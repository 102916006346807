@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.cartSummary {
  @media (min-width: $desktop-min-breakpoint) {
    padding: 0 0 0 30px;
  }
}

.noCartSummaryPadding {
  padding: 0;
}

.cartSummaryCheckout {
  padding-top: var(--spacing-l);
}

.cartTotals {
  margin-bottom: 0;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0 16px;

    &:first-of-type {
      padding-top: 8px;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid var(--grey-mid);
    }
  }

  dd {
    font-family: 'Basis-Grotesque-Mono-Pro';
    font-size: rem-calc(16);
  }

  &__grandTotal {
    padding: 16px 0 0 !important;

    dt {
      font-size: rem-calc(24);
    }

    dd {
      font-size: rem-calc(36);
      letter-spacing: 0.5px;
    }
  }

  &__Shipping,
  &__Tax,
  &__Discount {
    color: var(--charcoal);
  }
}

.cartActions {
  margin: 40px 0;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  a,
  button,
  > div {
    margin-left: 20px;
  }
}

.shareModal {
  width: 780px;
  max-width: 100%;

  input {
    margin: 32px 0;
  }
}

.CartSummary__CopyUrlRow {
  display: flex;
  gap: var(--spacing-base);
  padding-top: 0;
  padding-bottom: var(--spacing-m);

  & > :first-child {
    flex-basis: 100%;
    input {
      font-size: rem-calc(14);

      @media screen and (min-width: $tablet-min-breakpoint) {
        font-size: rem-calc(20);
      }
    }
  }

  @media screen and (min-width: $tablet-min-breakpoint) {
    padding-bottom: var(--spacing-l);
    gap: var(--grid-margin-desktop);
  }
}
