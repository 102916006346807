@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.fontFamilyGroup {
  margin: 0 0 var(--spacing-m);
  max-width: var(--tablet-content-max-width);

  p:last-of-type {
    margin: var(--spacing-m) 0;
  }

  p:first-of-type {
    margin: 1rem 0;
  }
}

.fontFamilyGroupDisabled {
  opacity: 0.3;
}

.heading {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;

  border-bottom: 1px solid var(--grey-mid);
}

.title {
  flex-grow: 1;

  margin-left: 16px;

  h5 {
    text-transform: capitalize;
  }

  p {
    font-size: rem-calc(14);
    margin-top: 4px;
  }
}

@keyframes expand {
  from {
    max-height: 0;
  }
  to {
    max-height: 800px;
  }
}

.expanded,
.hidden {
  overflow: hidden;
}

.expanded {
  animation: expand 0.4s var(--transiton-timing-ease) 1;
}

.hidden {
  max-height: 0;
}

.variableFontSection {
  margin-top: var(--spacing-m);

  .variableFontSection__heading {
    margin-bottom: var(--spacing-s);
  }
}
