@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.CopyButton {
  span {
    font-size: rem-calc(40);
    line-height: 1;
  }
}

@keyframes change-color {
  from {
    color: black;
  }
  to {
    color: grey;
  }
}

.Clicked {
  span {
    animation-name: change-color;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
  }
}
