@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.tag {
  --foreground-color: var(--description-list-foreground-color, var(--theme-override-color));

  display: inline-block;
  color: var(--foreground-color, var(--charcoal));
  font-size: rem-calc(12);
  line-height: 1.3;
  text-transform: uppercase;

  padding: 4px 8px;
  border: 1px solid var(--foreground-color, var(--charcoal));
  border-radius: 50px;

  margin-right: 8px;

  white-space: nowrap;

  background-color: transparent;

  margin-bottom: var(--spacing-xs);
}

.tagAsButton {
  cursor: pointer;
  &:hover {
    color: var(--black);
    border: 1px solid var(--grey);
    background-color: var(--grey);
  }
}

.error {
  color: var(--error);
  border: 1px solid var(--error);
}
