@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.grid {
  width: 100%;
  background-color: var(--grid-background);

  --_gutter: var(--gutter);
  @media (min-width: $tablet-min-breakpoint) {
    --_gutter: var(--custom-gutter, var(--gutter));
  }
}

.row {
  --column: calc(100% / 12);

  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;

  width: 100%;
  max-width: var(--page-width);

  @media (max-width: $mobile-max-breakpoint) {
    --column: calc(100% / 12);
  }

  padding-left: calc(var(--grid-margin) - var(--_gutter));
  padding-right: calc(var(--grid-margin) - var(--_gutter));
}

.row .row {
  margin: 0 calc(var(--_gutter) * -1);
  width: auto;
}

.gridFullWidth {
  > div {
    padding: 0;
    max-width: 100%;
  }
}

.gridFullHeight {
  height: 100%;
  .row {
    height: 100%;
  }
}

@for $i from 1 through 12 {
  .column-sm-#{$i} {
    flex: 0 0 calc(var(--column) * #{$i});
    max-width: calc(var(--column) * #{$i});

    padding-left: var(--_gutter);
    padding-right: var(--_gutter);

    position: relative;
  }
}

@media screen and (max-width: $mobile-max-breakpoint) {
  .column-sm-0 {
    display: none;
  }
}

@media screen and (min-width: $tablet-min-breakpoint) {
  @for $i from 1 through 12 {
    .column-md-#{$i} {
      flex: 0 0 calc(var(--column) * #{$i});
      max-width: calc(var(--column) * #{$i});

      padding-left: var(--_gutter);
      padding-right: var(--_gutter);

      position: relative;
    }
  }

  @for $i from 1 through 12 {
    .column-order-d-#{$i} {
      order: $i;
    }
  }
}

@media screen and (min-width: $tablet-min-breakpoint) and (max-width: $tablet-max-breakpoint) {
  .column-md-0 {
    display: none;
  }
}

@media screen and (min-width: $desktop-min-breakpoint) {
  @for $i from 1 through 12 {
    .column-lg-#{$i} {
      flex: 0 0 calc(var(--column) * #{$i});
      max-width: calc(var(--column) * #{$i});

      padding-left: var(--_gutter);
      padding-right: var(--_gutter);

      position: relative;
    }
  }
}

@media screen and (min-width: $desktop-min-breakpoint) {
  .column-lg-0 {
    display: none;
  }
}

@media screen and (min-width: $desktop-large-min-breakpoint) {
  @for $i from 1 through 12 {
    .column-xl-#{$i} {
      display: block;
      flex: 0 0 calc(var(--column) * #{$i});
      max-width: calc(var(--column) * #{$i});

      padding-left: var(--_gutter);
      padding-right: var(--_gutter);

      position: relative;
    }
  }

  .column-xl-0 {
    display: none;
  }
}

.reverse-mobile-vertical {
  @media (max-width: $tablet-max-breakpoint) {
    flex-direction: column-reverse;
  }
}

.reverse-mobile-horizontal {
  @media (max-width: $tablet-max-breakpoint) {
    flex-direction: row-reverse;
  }
}
