@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.Spinner {
  position: fixed;
  z-index: 90;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;

  fill: var(--color-primary);

  svg {
    max-width: 80px;
  }
}

.SpinnerWhite {
  background-color: var(--white);
}

.SpinnerGrey {
  background-color: var(--grey-page);
}

.SpinnerWhiteCharcoal {
  background-color: var(--charcoal, #666666);
}

.SpinnerContextual {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 14;
}

.SpinnerRelative {
  position: relative;
  height: auto;
  width: auto;
}

.disc {
  transform-origin: center center;
  animation: rotate 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67) forwards infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// .st0,
// .st1,
// .st2 {
//   fill: none;
//   stroke: var(--black);
//   stroke-width: 2;
//   stroke-linecap: round;
//   stroke-linejoin: round;
//   stroke-miterlimit: 10;
//   stroke-dashoffset: 0;
//   animation: dash-one 2.5s cubic-bezier(0.17, 0.67, 0.83, 0.67) forwards infinite;

//   .SpinnerWhiteCharcoal & {
//     stroke: var(--white, #ffffff);
//   }
// }

// .st0 {
//   stroke-dasharray: 23.8;
//   animation-name: dash-one;
//   /*   animation-direction: reverse; */
// }

// .st1 {
//   stroke-dasharray: 34.6;
//   animation-name: dash-two;
// }

// .st2 {
//   stroke-dasharray: 94;
//   animation-name: dash-three;
// }

// @keyframes dash-one {
//   0% {
//     stroke-dashoffset: 0;
//   }
//   20% {
//     stroke-dashoffset: 23.8;
//   }
//   40% {
//     stroke-dashoffset: 47.6;
//   }
//   59.999% {
//     stroke-dashoffset: 47.6;
//   }
//   60% {
//     stroke-dashoffset: 0;
//   }
// }

// @keyframes dash-two {
//   0% {
//     stroke-dashoffset: 0;
//   }
//   20% {
//     stroke-dashoffset: 34.6;
//   }
//   40% {
//     stroke-dashoffset: 69.2;
//   }
//   59.999% {
//     stroke-dashoffset: 69.2;
//   }
//   60% {
//     stroke-dashoffset: 0;
//   }
// }

// @keyframes dash-three {
//   0% {
//     stroke-dashoffset: 0;
//   }
//   20% {
//     stroke-dashoffset: 94;
//   }
//   40% {
//     stroke-dashoffset: 188;
//   }
//   59.999% {
//     stroke-dashoffset: 188;
//   }
//   60% {
//     stroke-dashoffset: 0;
//   }
// }
