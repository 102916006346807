@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.DropdownItem {
  display: flex;
  align-items: center;
  margin: 8px 0;
  cursor: pointer;
  font-size: rem-calc(20);
  line-height: rem-calc(30);
  text-decoration: none;

  border: none;
  background-color: transparent;
  padding: unset;
  color: var(--dropdown-item-color, var(--charcoal));

  &__label {
    font-size: 0.75em;
    text-decoration: none;
    margin-left: 8px;
  }

  &:hover {
    color: var(--dropdown-item-color, var(--charcoal));
  }

  &:hover,
  &:active {
    text-decoration: underline;
  }
}

.DropdownItem--active {
  text-decoration: underline;
}

.DropdownItemSmall,
.DropdownItemWide {
  font-size: rem-calc(16);
  line-height: rem-calc(20);
  color: var(--dropdown-item-color, var(--dropdown-color));
}
