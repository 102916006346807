@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.container {
  bottom: 0;
  position: sticky;
  z-index: 8;

  width: 100%;
  margin: 0;

  padding-bottom: 40px;
  pointer-events: none;

  button {
    pointer-events: all;
  }

  @media screen and (max-width: $tablet-max-breakpoint) {
    pointer-events: all;

    margin: var(--spacing-m);
    width: calc(100% - 2 * var(--spacing-m));
    padding: 8px;

    bottom: var(--spacing-m);

    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
    border-radius: 32.5px;
    background-color: var(--white);
  }
}

.stepNavigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  max-width: var(--page-width);

  @media screen and (min-width: $desktop-min-breakpoint) {
    width: 100%;
    padding-left: calc(var(--grid-margin) - var(--gutter));
    padding-right: calc(var(--grid-margin) - var(--gutter));
  }
}

.summaryButton {
  flex-grow: 1;
}

.back,
.next {
  min-width: 37px;
  @media screen and (min-width: $desktop-min-breakpoint) {
    padding: 0 var(--gutter);
  }
}

.back button {
  --button-background: var(--grey-light);
}

@media screen and (max-width: $tablet-max-breakpoint) {
  .backLabel,
  .nextLabel {
    display: none;
  }
  .next button {
    padding: rem-calc(5.5);
    width: 100%;
  }
  .back button {
    width: 100%;
    padding: rem-calc(5.5);
    --button-background: var(--white);
  }
}

@keyframes openAnimation {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes closeAnimation {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(100%);
  }
}

.navigationSummary {
  position: absolute;

  max-height: 90vh;
  overflow: scroll;

  width: 100vw;
  bottom: -24px;
  left: -24px;
  animation: openAnimation ease 0.2s;
}

.navigationSummaryClose {
  position: absolute;
  top: 21px;
  right: 16px;
}

.closeAnimation {
  animation: closeAnimation ease 0.2s;
}
