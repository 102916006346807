// Colour Schemes
.u-primary {
  --heading-color: var(--primary);
  --text-color: var(--black);
  --link-color: var(--primary);

  --grid-background: var(--light-bg);
}

.u-secondary {
  --heading-color: var(--secondary);
  --text-color: var(--white);
  --link-color: var(--secondary);

  --grid-background: var(--primary);

  --button-primary-background: var(--secondary);
  --button-primary-color: var(--black);
  --button-primary-background-hover: var(--secondary);
  --button-primary-color-hover: var(--black);
  --button-primary-background-active: var(--secondary);
  --button-primary-color-active: var(--black);
  --button-radius: 0;
}

.u-alternative {
  --heading-color: var(--primary);
  --text-color: var(--black);
  --link-color: var(--primary);

  --grid-background: var(--pastel-aqua);

  --button-primary-background: var(--pastel-coral);
  --button-primary-color: var(--black);
  --button-primary-background-hover: var(--pastel-coral);
  --button-primary-color-hover: var(--black);
  --button-primary-background-active: var(--pastel-coral);
  --button-primary-color-active: var(--black);
}

[class*='th-'] {
  @media screen and (max-width: $tablet-max-breakpoint) {
    &[class*='Header_headerMenuOpen'] {
      --header-color: var(--black);
    }
  }
}

.th-black-white {
  --color-primary: var(--black);
  --color-background: var(--white);
}

.th-white-black {
  --color-primary: var(--white);
  --color-background: var(--black);
  --header-icon-color: var(--white);
  --header-color: var(--white);
}

.th-white-deepblue {
  --color-primary: var(--white);
  --color-background: #000033;
  --header-icon-color: var(--white);
  --header-color: var(--white);
}

.th-charcoal-white {
  --color-primary: var(--charcoal);
  --color-background: var(--white);
}

.th-white-charcoal {
  --color-primary: var(--white);
  --color-background: var(--charcoal);
  --button-color: var(--color-primary);

  --icon-stroke-color: var(--color-primary);

  --toggle-border-color: var(--white);
  --toggle-on-background: var(--color-primary);

  --checkbox-icon: url('/checkmark-charcoal.svg');
  --checkbox-background: transparent;
  --checkbox-checked-background: var(--color-primary);
  --checkbox-border: var(--color-primary);
  --checkbox-border-color: var(--color-primary);
  --checkbox-checked-image-label-color: var(--color-primary);

  --dropdown-active-background-color: var(--color-primary);

  --input-color-border: var(--color-primary);

  --card-hover-background: var(--grey-mid);
  --card-hover-color: var(--black);
}

.th-black-grey {
  --color-primary: var(--black);
  --color-background: var(--grey-page);
}
.th-black-lightgrey {
  --color-primary: var(--black);
  --color-background: var(--grey-light);
}
.th-black-grey-hero {
  --color-primary: var(--black);
  --color-background: var(--grey-mid);
}
.th-grey-white {
  --color-primary: var(--charcoal);
  --color-background: var(--white);
}

.th-white-blue {
  --color-primary: var(--white);
  --color-background: var(--blueprint);

  --button-disabled-background: var(--white);
  --button-disabled-opacity: 0.3;
}

.th-blue-white {
  --color-primary: var(--blueprint);
  --color-background: var(--white);
}

.th-black-yellow {
  --color-primary: var(--black);
  --color-background: var(--yellow);
}

.th-black-beige {
  --color-primary: var(--black);
  --color-background: var(--yellow-guide);
}

.th-yellow-hero {
  --color-primary: var(--black);
  --color-background: var(--yellow-hero);
}

.th-black-brown {
  --color-primary: var(--black);
  --color-background: var(--fann-grotesque);
}

.th-page-theme {
  --color-primary: var(--page-theme-primary);
  --color-background: var(--page-theme-background);
}

/**
 Header Themes
**/

.th-header-white {
  --fixed-nav-link-color: var(--white);
  --header-color: var(--white);
  --header-icon-color: var(--white);
  --color-background: var(--black);
  --color-primary: var(--white);
  --dropdown-item-color: var(--black);
}

/**
 Page Themes
**/

.page-theme-grey {
  background-color: var(--grey-page);
  --link-color: var(--charcoal);
}

.page-theme-charcoal {
  background-color: var(--charcoal);
  --link-color: var(--white);
}

.page-theme-black {
  background-color: var(--black);
  --color-primary: var(--white);

  --header-icon-color: var(--color-primary);
  --header-color: var(--color-primary);
  --link-color: var(--color-primary);
  --icon-color: var(--color-primary);
}

.page-padding-top {
  padding-top: calc(65px + var(--ticker-height));

  @media (min-width: $tablet-min-breakpoint) {
    padding-top: calc(88px + var(--ticker-height));
  }

  @media (min-width: $desktop-min-breakpoint) {
    padding-top: calc(93px + var(--ticker-height));
  }
}

.page-padding-bottom {
  padding-bottom: 125px;
}

.page-loading-container {
  min-height: 600px;
  position: relative;
}

/* Color classes */

.apercu {
  color: var(--apercu);
}
.apercu-cond {
  color: var(--apercu-cond);
}
.archive {
  color: var(--archive);
}
.auguste {
  color: var(--auguste);
}
.auguste-serif {
  color: var(--auguste-serif);
}
.auguste-sans {
  color: var(--auguste-sans);
}
.basis {
  color: var(--basis);
}
.brick {
  color: var(--brick);
}
.brick-2 {
  color: var(--brick-2);
}
.burgess {
  color: var(--burgess);
}
.castledown {
  color: var(--castledown);
}
.central-ave-black {
  color: var(--central-ave-black);
}
.coign {
  color: var(--coign);
}
.columba {
  color: var(--columba);
}
.fold-grotesque {
  color: var(--fold-grotesque);
}
.fortescue {
  color: var(--fortescue);
}
.forme {
  color: var(--forme);
}
.fann-grotesque {
  color: var(--fann-grotesque);
}
.garton-black {
  color: var(--garton-black);
}
.goodall-1 {
  color: var(--goodall-1);
}
.goodall-2 {
  color: var(--goodall-2);
}
.grenette {
  color: var(--grenette);
}
.guida {
  color: var(--guida);
}
.la-fabrique {
  color: var(--la-fabrique);
}
.leroy {
  color: var(--leroy);
}
.lisbon {
  color: var(--lisbon);
}
.lydia {
  color: var(--lydia);
}
.mabry {
  color: var(--mabry);
}
.mabry-l {
  color: var(--mabry-1);
}
.mabry-mono {
  color: var(--mabry-mono);
}
.mad-sans {
  color: var(--mad-sans);
}
.mad-serif {
  color: var(--mad-serif);
}
.marche {
  color: var(--marche);
}
.midnight-sans {
  color: var(--midnight-sans);
}
.monosten {
  color: var(--monosten);
}
.montefiore {
  color: var(--montefiore);
}
.nib-1 {
  color: var(--nib-1);
}
.nib-2 {
  color: var(--nib-2);
}
.pantograph {
  color: var(--pantograph);
}
.pdu {
  color: var(--pdu);
}
.peggs {
  color: var(--peggs);
}
.PEP {
  color: var(--PEP);
}
.perfin {
  color: var(--perfin);
}
.pin {
  color: var(--pin);
}
.raissone {
  color: var(--raissone);
}
.reader {
  color: var(--reader);
}
.relative {
  color: var(--relative);
}
.selva {
  color: var(--selva);
}
.sunset-gothic {
  color: var(--sunset-gothic);
}
.system85 {
  color: var(--system85);
}
.transcript {
  color: var(--transcript);
}
.value-sans {
  color: var(--value-sans);
}
.value-serif {
  color: var(--value-serif);
}
.visuelt {
  color: var(--visuelt);
}

// Type themes

.th-apercu {
  background-color: var(--apercu);
}
.th-apercu-cond {
  background-color: var(--apercu-cond);
}
.th-archive {
  background-color: var(--archive);
}
.th-auguste {
  background-color: var(--auguste);
}
.th-auguste-serif {
  background-color: var(--auguste-serif);
}
.th-auguste-sans {
  background-color: var(--auguste-sans);
}
.th-basis {
  background-color: var(--basis);
}
.th-brick {
  background-color: var(--brick);
}
.th-brick-2 {
  background-color: var(--brick-2);
}
.th-burgess {
  background-color: var(--burgess);
}
.th-castledown {
  background-color: var(--castledown);
}
.th-central-ave-black {
  background-color: var(--central-ave-black);
}
.th-coign {
  background-color: var(--coign);
}
.th-columba {
  background-color: var(--columba);
}
.th-fortescue {
  background-color: var(--fortescue);
}
.th-forme {
  background-color: var(--forme);
}
.th-fann-grotesque {
  background-color: var(--fann-grotesque);
}
.th-garton-black {
  background-color: var(--garton-black);
}
.th-goodall-1 {
  background-color: var(--goodall-1);
}
.th-goodall-2 {
  background-color: var(--goodall-2);
}
.th-grenette {
  background-color: var(--grenette);
}
.th-guida {
  background-color: var(--guida);
}
.th-la-fabrique {
  background-color: var(--la-fabrique);
}
.th-leroy {
  background-color: var(--leroy);
}
.th-lisbon {
  background-color: var(--lisbon);
}
.th-lydia {
  background-color: var(--lydia);
}
.th-mabry {
  background-color: var(--mabry);
}
.th-mabry-l {
  background-color: var(--mabry-1);
}
.th-mabry-mono {
  background-color: var(--mabry-mono);
}
.th-mad-sans {
  background-color: var(--mad-sans);
}
.th-mad-serif {
  background-color: var(--mad-serif);
}
.th-marche {
  background-color: var(--marche);
}
.th-midnight-sans {
  background-color: var(--midnight-sans);
}
.th-monosten {
  background-color: var(--monosten);
}
.th-montefiore {
  background-color: var(--montefiore);
}
.th-nib-1 {
  background-color: var(--nib-1);
}
.th-nib-2 {
  background-color: var(--nib-2);
}
.th-pantograph {
  background-color: var(--pantograph);
}
.th-pdu {
  background-color: var(--pdu);
}
.th-peggs {
  background-color: var(--peggs);
}
.th-PEP {
  background-color: var(--PEP);
}
.th-perfin {
  background-color: var(--perfin);
}
.th-pin {
  background-color: var(--pin);
}
.th-raissone {
  background-color: var(--raissone);
}
.th-reader {
  background-color: var(--reader);
}
.th-relative {
  background-color: var(--relative);
}
.th-selva {
  background-color: var(--selva);
}
.th-sunset-gothic {
  background-color: var(--sunset-gothic);
}
.th-system85 {
  background-color: var(--system85);
}
.th-transcript {
  background-color: var(--transcript);
}
.th-value-sans {
  background-color: var(--value-sans);
}
.th-value-serif {
  background-color: var(--value-serif);
}
.th-visuelt {
  background-color: var(--visuelt);
}

.charcoal {
  color: var(--charcoal);
}
