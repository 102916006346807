:root {
    --lisbon-red: #eb0007;
--raisonne-yellow: #ffff00;
--experimental-green: #008000;
--midnight-sans-blue: #000132;
--brick-pink: #fb927d;
--midnight-sans-yellow: #EFFD3D;
--brick-green: #1f4758;
--darkgrey: #2d2d2d;
--sunset-gothic-yellow: #FFEB9B;
--custom-ng-pink: #fce5d8;
--custom-bugerking-brown: #551D0E;
--custom-burgerking-cream: #F4EBDC;
--system85-green: #00787d;
--system85-pink: #ff5aff;
--system85-red: #ff4637;
--white: #ffffff;
--selva-blue: #0013A2;
--selva-pink: #F9E7D5;
--apercu-green: #00b259;
--apta-blue: #4050ea;
--archive-yellow: #ffd715;
--archive-red: #eb0007;
--auguste-red: #eb002e;
--auguste-cream: #f5f4ea;
--auguste-blue: #1b1fa6;
}