@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.button {
  font-size: 1rem;
  font-weight: 400;

  line-height: 1.2;
  letter-spacing: 0.5px;

  color: var(--link-color);
  text-decoration: underline;

  &:focus {
    outline: none;
  }
}

.iconWrapper {
  column-gap: 8px;
}

.isPrimary,
.isSecondary,
.isSecondary--filled,
.isError {
  @media (hover: hover) {
    &:hover {
      color: var(--button-hover-color);
      background: var(--button-hover-background);
      border: var(--button-hover-border);
      text-decoration: none;
      --icon-color: var(--button-hover-color);
    }
  }

  &[disabled] {
    pointer-events: none;
    cursor: not-allowed;
    background-color: var(--button-disabled-background);
    border-color: var(--button-disabled-border);
    opacity: var(--button-disabled-opacity);
  }
}

.isPrimary,
.isSecondary,
.isSecondary--filled,
.isTertiary,
.isGhost,
.isError {
  display: inline-block;
  vertical-align: middle;

  padding: var(--button-base-padding);
  text-decoration: none;

  line-height: 1;

  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  transition: color 0.2s var(--transiton-timing-ease),
    background-color 0.2s var(--transiton-timing-ease),
    border-color 0.2s var(--transiton-timing-ease), opacity 0.2s ease-out;
  border-radius: var(--button-radius);

  background-color: var(--button-background);
  color: var(--button-color);
  border: var(--button-border);
  --icon-color: var(--button-color);

  &:focus {
    outline: none;

    &:focus-visible {
      @media (min-width: $desktop-min-breakpoint) {
        outline: 2px dashed var(--leroy);
        outline-offset: 2px;
      }
    }
  }
}

.isPrimary {
  --button-color: var(--color-background);
  --button-background: var(--color-primary);
  --button-border: 1px solid var(--color-primary);
}

.isSecondary {
  --button-background: transparent;
  --button-color: var(--color-primary);
  --button-border: 1px solid var(--color-primary);
  --button-hover-background: var(--grey);
  --button-hover-color: var(--black);
  --button-hover-border: 1px solid var(--grey);
}

.isSecondary--filled {
  --button-background: var(--grey-page);
  --button-color: var(--color-primary);
  --button-border: 1px solid var(--color-primary);
  --button-hover-background: var(--grey);
  --button-hover-color: var(--black);
  --button-hover-border: 1px solid var(--grey);
}

.isTertiary {
  --button-color: var(--color-background);
  --button-background: var(--yellow-grey);
  --button-border: 1px solid var(--yellow-grey);
  font-family: 'Basis-Grotesque-Pro';
  &:hover {
    background: var(--yellow-grey);
    color: var(--color-background);
    border: 1px solid var(--yellow-grey);
    cursor: default;
  }
}

.isError {
  --button-background: transparent;
  --button-color: var(--color-primary);
  --button-border: 1px solid var(--error);
  --button-hover-background: var(--error);
  --button-hover-color: var(--white);
  --button-hover-border: 1px solid var(--error);
}

.isBlank {
  background: none;
  border-radius: 2rem;
  padding: 1rem 2rem;
  color: var(--light-blue);
}

.isMuted,
.button:disabled,
.isMuted:disabled {
  cursor: not-allowed;
  opacity: 0.333;
}

.isMuted:disabled {
  pointer-events: none;
}

.fitWidth {
  width: fit-content;
}

.align-left {
  margin-left: 0;
}

.align-center {
  margin: 0 auto;
  display: block;
}

.align-right {
  margin-right: 0;
  margin-left: auto;
  display: block;
}

.align-float-right {
  float: right;
  margin-left: 14px;
}

.size-s {
  padding: var(--button-s-padding);
  font-size: rem-calc(14);
  line-height: 1.3;
}

.size-l {
  padding: var(--button-l-padding);
  font-size: rem-calc(16);
  line-height: 1.5;

  @media (max-width: $mobile-max-breakpoint) {
    //  L becomes S on mobile
    padding: var(--button-s-padding);
    font-size: rem-calc(14);
    line-height: 1.3;
  }
}

.size-xl {
  padding: var(--button-xl-padding);
  font-size: rem-calc(24);

  @media (max-width: $mobile-max-breakpoint) {
    //  XL becomes L on mobile
    padding: var(--button-l-padding);
    font-size: rem-calc(16);
    line-height: 1.5;
  }
}

.isGhost {
  background: none;
  padding: 0;
  border-radius: 0;
}

.asLink,
.asLinkActive {
  position: relative;
  font-size: rem-calc(20);
  font-weight: 400;
  color: var(--charcoal);
  border: none;
  letter-spacing: 0.5px;
  background: none;
  padding: 0;
  cursor: pointer;

  &:hover,
  &.isActive {
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      top: calc(100% + 2px);
      background-color: currentColor;
    }
  }
}

.asLinkActive {
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    top: calc(100% + 2px);
    background-color: currentColor;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  font-feature-settings: 'ss08' on;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-weight: 400;
}

.forceWhite {
  color: var(--white);
}
