// Ensure these match: lib/constants.js
$mobile-max-breakpoint: 767px;

$tablet-min-breakpoint: 768px;
$tablet-max-breakpoint: 1023px;

$desktop-min-breakpoint: 1024px;
$desktop-max-breakpoint: 1239px;

$desktop-large-min-breakpoint: 1240px;
$desktop-large-max-breakpoint: 1599px;

$desktop-superlarge-min-breakpoint: 1600px;
$row-max-width: 1430px;
