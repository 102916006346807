@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.ModalTemplate__header {
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-m) var(--spacing-l);
  border-bottom: 2px solid var(--grey-page);

  @media screen and (max-width: $tablet-min-breakpoint) {
    // padding: var(--spacing-s);
    padding: var(--spacing-base);
  }
}

.ModalTemplate__body {
  padding: 0 var(--spacing-l);
  font-size: rem-calc(22);

  a {
    text-decoration: none;
    & > span {
      // text-decoration: none;
    }
    :hover {
      text-decoration: underline;
    }
  }

  p {
    line-height: 125%;
    letter-spacing: 0;

    a {
      text-decoration: none;
      & > span {
        text-decoration: none;
      }

      :hover {
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: $tablet-min-breakpoint) {
    padding: 0 rem-calc(20);
    padding-bottom: 0;
    font-size: rem-calc(14);
    span {
      font-size: rem-calc(14);
    }
    p {
      span {
        font-size: rem-calc(14);
      }
    }
  }
}

.ModalTemplate__title {
  padding-bottom: var(--spacing-m);
  margin-bottom: var(--spacing-m);

  font-size: rem-calc(36);
  line-height: rem-calc(44);

  @media screen and (max-width: $tablet-min-breakpoint) {
    padding-bottom: var(--spacing-xs);
    margin-bottom: var(--spacing-xs);

    font-size: rem-calc(24);
    line-height: 125%;

    border-bottom: 1px solid var(--grey-page);
  }
}

.ModalTemplate__bodyBlock {
  // @media screen and (max-width: $tablet-min-breakpoint) {
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-xs);

  & > :first-child {
    padding-bottom: var(--spacing-xs);
    margin-bottom: var(--spacing-xs);
    border-bottom: 1px solid var(--grey-page);
  }
  p {
    line-height: rem-calc(17.5px);
  }

  @media screen and (min-width: $tablet-min-breakpoint) {
    padding-top: var(--spacing-base);
    padding-bottom: var(--spacing-xs);

    & > :first-child {
      padding-bottom: var(--spacing-base);
      margin-bottom: var(--spacing-base);
    }

    p {
      line-height: inherit;
    }
  }
}
