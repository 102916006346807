@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.lineItem {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;

  border-bottom: 1px solid var(--grey-mid);
  padding: 10px 0;

  column-gap: 10px;

  &__name {
    font-size: rem-calc(16);
    font-weight: 400;

    a {
      text-decoration: none;
    }
  }

  &__image {
    position: relative;

    height: 47px;
    width: 56px;

    padding: 10px;

    border: 1px solid var(--grey-mid);
    border-radius: var(--border-radius-small);
    overflow: hidden;
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    flex: 1;

    & > div {
      flex: 0 0 100%;
      margin-top: 10px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    -moz-column-gap: var(--actions-gap);
    column-gap: var(--actions-gap);
    justify-content: flex-end;
    flex: 2;

    button {
      display: flex;
      align-items: center;
    }
  }

  &__licence {
    margin: 0 auto;

    @media screen and (max-width: $tablet-max-breakpoint) {
      display: none;
    }
  }

  &__price {
    p {
      font-family: 'Basis-Grotesque-Mono-Pro';
    }
  }

  &__priceCart {
    p {
      font-family: 'Basis-Grotesque-Mono-Pro';
    }
  }

  &__licenceMobile {
    display: none;

    @media screen and (max-width: $tablet-max-breakpoint) {
      flex-basis: 100%;
      display: flex;
      margin-top: 8px;

      button {
        width: 100%;
      }
    }
  }
}

.lineItemProduct,
.lineItemProductCart {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;

  column-gap: 10px;
  padding: var(--spacing-base) 0;

  --delete-button-width: 24px;
  --actions-gap: 10px;

  &__name {
    font-size: rem-calc(16);
    font-weight: 400;

    a {
      text-decoration: none;
    }
  }

  &__image {
    position: relative;

    height: 47px;
    width: 56px;

    padding: 10px;

    border: 1px solid var(--grey-mid);
    border-radius: var(--border-radius-small);
    overflow: hidden;
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    flex: 1;

    & > div {
      flex: 0 0 100%;
      margin-top: 10px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    -moz-column-gap: 10px;
    column-gap: 10px;
    justify-content: flex-end;
    flex: 2;

    button {
      display: flex;
      align-items: center;
    }
  }

  &__licence {
    margin: 0 auto;

    @media screen and (max-width: $tablet-max-breakpoint) {
      display: none;
    }
  }

  &__licenceMobile {
    display: none;

    @media screen and (max-width: $tablet-max-breakpoint) {
      flex-basis: 100%;
      display: flex;
      margin-top: 8px;

      button {
        width: 100%;
      }
    }
  }
}

.lineItemProductCart {
  padding: var(--spacing-base);
}

.fontSample {
  width: 56px;

  padding: 10px;

  border: 1px solid var(--grey-mid);
  border-radius: var(--border-radius-small);
  font-family: var(--custom-font);
  text-align: center;
}

.physicalItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-s);

  &__header {
    display: flex;
    gap: var(--spacing-s);
    align-items: center;
  }

  &__Bottom {
    display: flex;
    align-items: center;
    padding-top: var(--spacing-s);
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid var(--grey-mid);
  }

  &__name {
    font-size: rem-calc(20);
    font-weight: 400;
    padding-right: 10px;

    a {
      text-decoration: none;
    }
  }

  &__sub {
    display: flex;
    gap: var(--spacing-s);
  }

  &__variant {
    font-size: rem-calc(16);
    font-weight: 400;
    text-transform: capitalize;
  }

  &TotalPrice {
    @media screen and (min-width: $desktop-min-breakpoint) {
      margin-right: calc(var(--actions-gap) + var(--icon-width));
    }
  }

  &__type {
    color: var(--charcoal);
  }
}

.physicalItemTotalPrice {
  p {
    font-family: 'Basis-Grotesque-Mono-Pro';
  }
}

.physicalItemQuantity {
  & > div {
    display: flex;
    align-items: center;
    gap: var(--spacing-s);

    label {
      font-size: rem-calc(14);
      margin: 0;
    }

    input {
      padding: 0 10px;
      height: 40px;
      text-align: center;
      max-width: 60px;
    }
  }

  & > div {
    align-items: center;
    flex-direction: row;
  }

  @media (max-width: $mobile-max-breakpoint) {
    & > div {
      align-items: flex-start;
      flex-direction: column;
    }
  }
}

.errors {
  width: 100%;
}

.quantityInput {
  font-size: rem-calc(16);
  font-family: 'Basis-Grotesque-Mono-Pro';
}
