:root {
  // Default colour vars used for theming
  --text-color: var(--black);
  --link-color: var(--primary);
  --heading-color: var(--black);
  --grid-background: transparent;
  --border-color: var(--light-grey);
  --icon-stroke-color: var(--color-primary);
  --icon-fill-color: transparent;

  --custom-font: inherit;

  --border-radius: 24px;
  --border-radius-small: 12px;
  --border-radius-large: 32px;

  // Transitions
  --transiton-timing-ease: cubic-bezier(0.65, 0, 0.35, 1);

  // Header
  --header-height: 94px;
  --header-color: var(--color-primary);
  --header-icon-color: var(--header-color);
  --ticker-height: 40px;

  // Grid
  --grid-margin: 48px;
  --gutter: calc(20px / 2); // should be HALF gutter
  @media screen and (max-width: $mobile-max-breakpoint) {
    --header-height: 65px;
    --grid-margin: 24px;
    --gutter: calc(24px / 2); // should be HALF gutter
  }

  --page-width: calc(1320px + 2 * var(--grid-margin));
  --page-margin: max(calc((100% - 1320px) / 2), var(--grid-margin));
  --page-indent: 122px;

  --tablet-content-max-width: 600px;

  --row-padding: 20px 0;
  --grid-margin-desktop: 20px;
  --grid-margin-mobile: 24px;

  // Spacing
  --spacing-xs: 8px;
  --spacing-s: 12px;
  --spacing-base: 16px;
  --spacing-m: 24px;
  --spacing-l: 32px;
  --spacing-xl: 48px;
  --spacing-2xl: 64px;

  // Icons
  --icon-width: 24px;
  --icon-height: 24px;

  // Cards
  --card-background: var(--white);
  --card-padding: 10px;
  --card-border-radius: 8px;
  --card-color: var(--text-color);

  // Forms
  --input-radius: 37px;
  --textarea-radius: 12px;
  --input-letterspacing: rem-calc(0.5);
  --placeholder-color: var(--opacity-black-015);
  --input-focus-border-color: var(--black);

  // Cart
  --price-width: 100px;

  // Buttons
  --hover-brightness: 1.2;
  --button-radius: 50px;

  --button-color: var(--color-primary);
  --button-background: var(--grey);
  --button-border: none;

  --button-hover-background: var(--grey);
  --button-hover-color: var(--black);
  --button-hover-border: 1px solid var(--grey);

  --button-disabled-background: var(--grey);
  --button-disabled-border: transparent;
  --button-disabled-opacity: 1;

  --button-base-padding: 12px var(--spacing-m);
  --button-s-padding: 8px var(--spacing-base);
  --button-l-padding: 10px var(--spacing-m);
  --button-xl-padding: 18px var(--spacing-m);

  // Toggle
  --toggle-border-color: var(--charcoal);
  --toggle-on-background: var(--charcoal);

  // Checkbox
  --checkbox-icon: url('/checkmark-white.svg');
  --checkbox-background: var(--white);
  --checkbox-checked-background: var(--black);
  --checkbox-checked-image-label-color: var(--black);
  --checkbox-border-color: var(--grey-primary);

  // radio
  --radios-size: 28px;
  --radios-border-size: 5px;
  --radios-border-color: 1px solid var(--grey-primary);
  --radios-border-color-active: 1px solid var(--white);

  // Dropdown
  --dropdown-active-background-color: var(--grey-colophon);

  --dropdown-background-color: var(--color-background);
  --dropdown-color: var(--color-primary);

  --dropdown-border-color: var(--dropdown-color);
  --dropdown-list-background: var(--grey-light);

  --dropdown-active-border-color: var(--grey-colophon);
}
