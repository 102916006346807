@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
// .cover {}

.modalOverlay {
  position: fixed;
  inset: 0px;
  z-index: 9999;
  background-color: transparent;
  backdrop-filter: blur(4px);

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: #000000;
    opacity: 0.6;
    z-index: -1;
  }
}

.modalContent {
  max-height: calc(88vh - 40px);
  overflow: scroll;

  h2 {
    font-size: rem-calc(24);
    line-height: 1.25;
    @media screen and (min-width: $tablet-min-breakpoint) {
      font-size: rem-calc(36);
    }
  }
}

// Hide scrollbar
.noScrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.modalCloseContainer {
  position: absolute;
  top: var(--spacing-m);
  right: var(--spacing-m);
  z-index: 1;
}

.modalCloseSplit {
  position: absolute;
  top: var(--spacing-m);
  right: var(--spacing-m);

  svg {
    @media screen and (min-width: $tablet-min-breakpoint) {
      stroke: var(--white);
    }
  }
}

.modalTriggerSplit {
  background: none;
  padding: 0;
  border: none;
  border-radius: 0;
}

// work together with <Card .v-in-use-modal styles
.cardModal {
  // Center
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  --modal-margin: 48px;

  background-color: #fff;
  border-radius: var(--border-radius);
  overflow: hidden;

  @media (max-width: $mobile-max-breakpoint) {
    --modal-margin: 24px;

    max-height: calc(100vh - 2 * var(--modal-margin));
    overflow: scroll;
  }

  .content {
    position: relative;

    img {
      max-height: 300px;
      @media (min-width: $tablet-min-breakpoint) {
        max-height: 600px;
      }
    }
  }

  .close {
    --color-primary: var(--white);
    --color-background: var(--black);
    --button-s-padding: 0;
    z-index: 5;
    position: absolute;
    right: 20px;
    top: 20px;

    @media (min-width: $tablet-min-breakpoint) {
      --button-s-padding: 8px 8px;
      top: var(--spacing-m);
    }
  }
}

/* Templates - to override styling of modal */
.modal-get-in-touch {
  // Center
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: #fff;
  border-radius: var(--border-radius);
  overflow: hidden;

  // padding: var(--spacing-m);

  @media (min-width: $tablet-min-breakpoint) {
    // padding: var(--spacing-xl);
  }
}
