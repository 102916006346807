@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.Radio {
  &Panel {
    --radios-size: 20px;
    --radios-border-size: 2px;
    label {
      padding: var(--spacing-s);
    }
  }
}

.RadioInput + label {
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-size: rem-calc(20);
  border-radius: var(--border-radius);

  @media (max-width: 500px) {
    font-size: rem-calc(16);
  }
}

.RadioInput + label span {
  width: var(--radios-size);
  min-width: var(--radios-size);
  height: var(--radios-size);
  box-shadow: 0 0 0 1px var(--charcoal);
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
  margin-right: var(--spacing-s);
}

.RadioInput + label span {
  background-color: var(--white);
  border: var(--radios-border-size) solid var(--white);
}

.RadioInput:checked + label span {
  background-color: var(--black);
}

.RadioInput:not(:checked):hover + label {
  background-color: var(--grey-mid);
}

.RadioInput:focus + label {
  outline: 1px dashed var(--input-focus-border-color);
  outline-offset: -2px;
}

.subLabel {
  margin-top: 6px;
  font-size: rem-calc(14);

  @media (max-width: 500px) {
    font-size: rem-calc(12);
  }
}

.mainLabel {
  display: flex;
  align-items: center;
}

.tag {
  margin-left: 10px;
  font-size: rem-calc(12);
  line-height: 15.6px;
  letter-spacing: 0.5;
  color: var(--charcoal);
  text-transform: uppercase;
  border: 1px solid var(--charcoal);
  padding: 4px 6px 4px 6px;
  border-radius: 50px;

  @media (max-width: 500px) {
    font-size: rem-calc(10);
    padding: 2px 4px 2px 4px;
  }
}

.labelContainer {
  width: fit-content;
}

.RadioPanel {
  .RadioInput:not(:checked) + label {
    border: var(--radios-border-color);
  }
  .RadioInput:checked + label {
    border: var(--radios-border-color-active);
    background-color: var(--white);
  }
}
