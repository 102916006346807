@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
// Input param :
// --checkbox-size

.Checkbox {
  position: relative;

  --_checkbox-size: var(--checkbox-size, 24px);
  --_checkbox-size__small: var(--checkbox-size, 16px);

  input:focus-visible ~ .CheckboxLabel {
    outline: 1px dashed var(--checkbox-border-color);
    outline-offset: 2px;
  }
}

.Checkbox input:checked {
  & + .CheckboxLabel--Panel {
    background-color: var(--white);
    border-color: var(--white);
  }

  & ~ .CheckboxLabel::before {
    --checkbox-border-color: var(--checkbox-checked-background);
    --scale: var(--checkbox-scale, 0.6);
    background: var(--checkbox-icon) center / calc(var(--_checkbox-size) * var(--scale)) no-repeat;
    background-color: var(--checkbox-checked-background);
  }

  & ~ .checkboxImage {
    .checkboxImageLabel {
      color: var(--checkbox-checked-image-label-color);
    }
  }
}

.Checkbox input:disabled {
  & ~ .CheckboxLabel:before {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.CheckboxLabel {
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  position: relative;
  z-index: 3;

  &__Content {
    display: flex;
    align-items: center;
  }

  &__LabelLarge {
    font-size: rem-calc(20);
    @media (min-width: $tablet-min-breakpoint) {
      font-size: rem-calc(24);
    }
  }

  &__BoxContent {
    border: 1px solid var(--grey-primary);
    border-radius: var(--border-radius-small);
    padding: var(--checkbox-boxcontent-padding, 12px);
    margin-right: 24px;
    font-family: var(--custom-font);
    font-size: rem-calc(30);

    & + span {
      position: relative;
      overflow: hidden;
    }

    & + span > div:first-of-type {
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      box-orient: vertical;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &::before {
    border-radius: 50%;
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: var(--_checkbox-size);
    min-width: var(--_checkbox-size);
    height: var(--_checkbox-size);
    border: 1px solid var(--checkbox-border-color);
    background-color: var(--checkbox-background);
    margin-right: 8px;
  }

  &--Small {
    &::before {
      width: var(--_checkbox-size__small);
      min-width: var(--_checkbox-size__small);
      height: var(--_checkbox-size__small);
    }
  }

  &--Panel {
    padding: var(--checkbox-panel-padding, 24px 24px);
    border: var(--radios-border-color);
    border-radius: 16px;
    z-index: 3;

    &::before {
      margin-right: 24px;
    }

    &:not(.CheckboxLabel--disabled):hover {
      background-color: var(--grey-mid);
    }
  }

  &--Border {
    border: 1px solid var(--checkbox-border-color);
  }

  &--disabled {
    cursor: not-allowed;
  }

  &--hideCheckbox {
    &::before {
      display: none;
    }
  }

  &--discount {
    box-shadow: 0px 3px 2px #b3b3b3;
    background-color: var(--grey-page);
  }

  &__SubLabel {
    font-size: rem-calc(14);
    line-height: 1.3;
    letter-spacing: 0.5px;
  }
}

.padding-s {
  padding: var(--spacing-base);
}

.DiscountWrapper {
  border: 1px solid var(--grey-primary);
  border-top: transparent;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  margin-top: -20px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  min-height: 65px;
  padding: 0 16px 12px;
  margin-bottom: var(--spacing-base);
  background-color: var(--grey-footer);
  padding-top: 25px;

  &--recommended {
    background-color: #feee35;
    p {
      align-self: center;
    }
  }

  span {
    color: var(--black);
    margin: 0;
  }

  #Label {
    margin: 0;
  }
}
