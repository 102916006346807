@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.InputFieldContainer {
  position: relative;
}

.InputPrefixWrapper {
  display: flex;
  align-items: stretch;
}

.InputPrefix {
  flex: 0 0 auto;

  display: flex;
  align-items: center;

  padding: 0 var(--spacing-s);
  margin-right: var(--spacing-s);

  background-color: var(--white);
  border-radius: var(--input-radius);

  min-width: 48px;

  font-family: 'Basis-Grotesque-Bold-Pro';
}

.InputField {
  width: 100%;
  padding: var(--spacing-s) 20px;
  border-radius: var(--input-radius);
  border: 0;
  letter-spacing: var(--input-letterspacing);
  font-size: rem-calc(20);
  line-height: 1;
  color: var(--black);

  &::placeholder {
    color: var(--placeholder-color);
  }

  &[type='password'] ~ .InputIcon {
    svg {
      --icon-color-internal: var(--charcoal);
    }
  }

  &:focus {
    -webkit-appearance: none;
    box-shadow: 0 0 0 1px black;
    outline: none;
  }
}

.InputFieldError {
  color: var(--red);
  border: 1px solid var(--red);
}

// option with border to show on white background
.InputFieldBorder {
  border: 1px solid var(--black);
}

.InputFieldIsDisabled {
  color: var(--grey-colophon);
  border: 1px solid var(--opacity-black-015);
}

.InputFieldIsOnWhite {
  background-color: var(--on-white-input);
}

.InputIcon {
  position: absolute;
  z-index: 1;
  transform: translate(-10%, 0);
  top: 40px;
  right: 16px;
  cursor: pointer;

  margin-top: 2px;
}

.InputIcon__Error {
  svg {
    // --icon-color-internal: var(--red);
  }
}

.InputMobileKeyboardWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-xs);

  input[type='number'] {
    -moz-appearance: textfield;
    color: var(--charcoal);

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
  }
}

.InputMobileKeyboardButton {
  --size: 24px;

  display: block;
  width: var(--size);
  height: var(--size);
  padding: 2px;

  border: 1px solid var(--color-primary);
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;

  @media screen and (min-width: $tablet-min-breakpoint) {
    order: 2;

    &:first-of-type {
      margin-right: 10px;
    }
  }

  &:disabled {
    opacity: 0.4;
  }
}

.InputMobileKeyboardButtonInner {
  display: flex;
  justify-content: center;
  align-self: center;
}

.InputMobileKeyboardButtonPlus,
.InputMobileKeyboardButtonMinus {
  position: relative;
  width: 15px;
  height: 1px;
  background-color: var(--color-primary);
}

.InputMobileKeyboardButtonPlus {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 1px;
    transform: rotate(90deg);
    background-color: inherit;
  }
}

.InputFieldPointerEventsDisabled {
  pointer-events: none;
  user-select: none;
}
