// * UI kit Colors
// https://www.figma.com/file/CdwF7DLIQlJMyhMxwobYBZ/Colophon?node-id=2%3A152

:root {
  // Primary
  --white: #ffffff;
  --black: #000000;
  --charcoal: #666666;
  --charcoal-footer: #4c4b4b;
  --grey: #b2b2b2;
  --grey-primary: #b3b3b3;
  --grey-mid: #d9d9d9;
  --grey-light: #f5f5f5;
  --grey-page: #f3f3f3;
  --grey-colophon: #e5e5e5;
  --grey-footer: #e8e8e8;
  --yellow-grey: #272726;

  // Secondary
  --error: #ff0000;
  --yellow: #feee35;
  --yellow-hero: #fcff57;
  --salmon: #ffd4b6;
  --blueprint: #0b54c2;
  --blueprint-prim: #0a65ee;
  --blue-footer: #003077;

  // Guide
  --yellow-guide: #ffeab6;
  --blue-gruid: #bee5f5;
  --pink-guide: #ffb6d0;

  // Typefaces
  --apercu: #00b259;
  --apercu-cond: #ffb4ff;
  --archive: #eb0006;
  --auguste: #f5f4ea;
  --auguste-serif: #1b1fa5;
  --auguste-Sans: #ea002e;
  --basis: #11069e;
  --brick: #fb927d;
  --brick-2: #1f4758;
  --burgess: #00c47b;
  --castledown: #005bbe;
  --central-ave-black: #000000;
  --coign: #9bb496;
  --columba: #fbe8d8;
  --fold-grotesque: #bb3118;
  --fortescue: #9833ff;
  --forme: #1c4387;
  --fann-grotesque: #651e22;
  --garton-black: #000000;
  --goodall-1: #0f2b17;
  --goodall-2: #32c74a;
  --grenette: #b6a4c8;
  --guida: #fddd19;
  --la-fabrique: #0171d2;
  --leroy: #fc6a51;
  --lisbon: #fedd00;
  --lydia: #3589a5;
  --mabry: #502d19;
  --mabry-1: #ff5023;
  --mabry-mono: #0173cd;
  --mad-sans: #003cff;
  --mad-serif: #ff1b00;
  --marche: #ef5723;
  --midnight-sans: #000132;
  --monosten: #fd2224;
  --montefiore: #006cb5;
  --nib-1: #ff9fff;
  --nib-2: #3c1d01;
  // --Pantograph: #??????;
  --pdu: #e6e6e6;
  --peggs: #4c4d4f;
  // --PEP: #??????;
  --perfin: #fd2224;
  --pin: #000000;
  // --Raissoné: #??????;
  --reader: #1a1a1a;
  --relative: #0093d1;
  --selva: #f9e7d5;
  --sunset-gothic: #ffeab6;
  --system85: #246b70;
  --transcript: #bfbfbf;
  --value-sans: #ff0c0d;
  --value-serif: #0441f7;
  --visuelt: #907d50;

  // globals

  // colors
  --primary: #4c96f7;
  --secondary: #ebebeb;

  --color-background: var(--white);
  --color-primary: var(--black);
  --color-secondary: var(--grey);

  --light-bg: #f7f3ea;
  --light-green-bg: #c1e2cc;
  --violet-bg: #dae5fa;
  --purple-bg: #f5dff2;
  --pastel-coral: #ffe4cc;
  --pastel-aqua: #d1f0ef;
  --on-white-input: #f3f3f3;

  --success: #35d28b;
  --orange: #fb9528;
  --red: #e22c3e;
  --light-blue: #1391d2;
  // --grey: #979797;
  --dark-grey: #293339;
  --light-grey: #ebebeb;
  --light-white: #f2f2f2;
  --dark-blue: #001489;

  --opacity-black-015: rgba(0, 0, 0, 0.15);
  --border-black: #b3b3b3;

  --slider-grey: #cbcbcb;
  --slider-grey-bg: #f3f3f3;
}
