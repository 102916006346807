@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.SelectField {
  display: inline-block;
  width: calc(100%);
  padding: 10px 43px 10px 20px;
  border-radius: var(--input-radius);
  border: 0;
  letter-spacing: var(--input-letterspacing);
  font-size: rem-calc(20);
  line-height: 1.4;
  text-overflow: ellipsis;
  overflow: hidden;

  &::placeholder {
    color: var(--placeholder-color);
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(/icon/arrow-down.svg) no-repeat right 16px center var(--white);
  color: var(--black);
}

.SelectFieldError {
  color: var(--red);
  border: 1px solid var(--red);
}

// option with border to show on white background
.SelectFieldBorder {
  border: 1px solid var(--black);
}

.SelectFieldIsDisabled {
  color: var(--grey-colophon);
  border: 1px solid var(--opacity-black-015);
}
