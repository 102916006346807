@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.placeholder {
  background: var(--page-grey);
  padding: 25% 0;
  text-align: center;
}

.container,
.containerCover {
  video {
    display: block;
    width: 100%;
  }
}

.container {
  position: relative;
}

.containerCover {
  position: relative;
  height: 100%;
  min-height: 100%;
  width: 100%;

  video {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.hasBorder {
  border: solid 1px transparent;
}

.border {
  &--white {
    border-color: var(--white);
  }

  &--normal-radius {
    border-radius: var(--border-radius);
  }
}

/* controls */
.controls,
.controls > * {
  padding: 0;
  margin: 0;
}
.controls {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: transparent;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-base);
  gap: var(--spacing-s);
  z-index: 2;
}
.controls[data-state='hidden'] {
  display: none;
}
.controls[data-state='visible'] {
  display: flex;
}

.controls .progress {
  cursor: pointer;
  flex: 1 1 90%;
  display: flex;
}
.controls button,
.soundControls button {
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: none;
  cursor: pointer;
  text-indent: -99999px;
  background: transparent;
  background-size: cover;
  background-repeat: no-repeat;
}

.controls button {
  flex: 0 0 32px;
  height: 32px;
}

.controls button[data-state='play'] {
  background-image: url('/icon/play.svg');
}
.controls button[data-state='pause'] {
  background-image: url('/icon/pause.svg');
}

.controls .seek {
  width: 100%;
  height: 1px;
  z-index: 2;
  cursor: pointer;
  -webkit-appearance: none;
  background-color: var(--color-primary);

  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    background: none;
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    background: var(--black);
    border: 1px solid #ffffff;
    border-radius: 50%;
  }

  /** FF*/
  &::-moz-range-track {
    background: var(--color-primary);
  }

  &::-moz-range-thumb {
    background: var(--color-primary);
  }
  /* IE*/
  input[type='range']::-ms-fill-lower {
    background: var(--color-primary);
  }
  input[type='range']::-ms-fill-upper {
    background: var(--color-primary);
  }
}

.soundControls {
  position: absolute;
  top: 0;
  right: 0;
  padding: var(--spacing-base);
  z-index: 2;
}

.soundControls button {
  width: 32px;
  height: 32px;
}

.soundControls button[data-state='mute'] {
  background-image: url('/icon/mute.svg');
}

.soundControls button[data-state='unmute'] {
  background-image: url('/icon/unmute.svg');
}
