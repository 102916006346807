@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.CustomisedFontMetadata {
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
  }

  &__item {
    width: 100%;
    font-size: rem-calc(14);
    line-height: 18.2px;
  }

  &__propertyName {
    color: var(--grey-primary);
    margin-right: 4px;
    text-transform: capitalize;
  }

  &__baseValue {
    display: inline-block;
    text-transform: capitalize;
  }
}
