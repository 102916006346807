@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.fontGroup,
.fontGroupDisabled {
  list-style-type: none;
  padding-left: 0px;
  margin-top: var(--spacing-m);

  --checkbox-boxcontent-padding: 6px;
  --checkbox-panel-padding: 12px;

  max-width: 800px;

  &Disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media screen and (min-width: $desktop-min-breakpoint) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    column-gap: var(--spacing-s);
  }

  li {
    margin-bottom: 8px;
  }

  @media screen and (max-width: $tablet-max-breakpoint) {
    li:first-child {
      margin-top: 4px;
    }
    li {
      margin-bottom: 4px;
    }
  }

  @media screen and (min-width: $desktop-large-min-breakpoint) {
    flex-direction: row;

    > li {
      flex-basis: calc(50% - 6px);
      max-width: calc(50% - 6px);
    }
  }
}
