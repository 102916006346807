@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.InputErrorMessage {
  margin-top: 6px;
  font-size: 0.75rem;
  line-height: 1.3;

  &::first-letter {
    text-transform: capitalize;
  }
}

.error {
  color: var(--red);
}
