@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.InputLabel {
  color: var(--color-primary);
  font-size: 1rem;
  line-height: 1.2;
  margin-bottom: 8px;
  letter-spacing: var(--input-letterspacing);

  &::first-letter {
    text-transform: capitalize;
  }
}

.error {
  color: var(--red);
}
