@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.container {
  display: flex;

  svg {
    min-width: 100%;
    min-height: 100%;
  }
}

.svg {
  transition: all 0.2s var(--transiton-timing-ease);
  --icon-color-internal: var(--icon-color, var(--color-primary));
}

.iconWithLabel {
  position: relative;

  & > div {
    position: absolute;
  }

  .label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
