$font-family: 'Basis-Grotesque-Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
  Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

@font-face {
  font-family: 'Basis-Grotesque-Pro';
  src: url('/fonts/CFBasis-Regular.woff2');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Basis-Grotesque-Light-Pro';
  src: url('/fonts/basis-grotesque-light-pro.woff2');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Basis-Grotesque-Medium-Pro';
  src: url('/fonts/CFBasis-Medium.woff2');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Basis-Grotesque-Bold-Pro';
  src: url('/fonts/CFBasis-Bold.woff2');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Basis-Grotesque-Mono-Pro';
  src: url('/fonts/CFBasisMonoPro-Regular.woff2');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Basis-Grotesque-Mono-Pro-Bold';
  src: url('/fonts/CFBasisMonoPro-Bold.woff2');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Basis-Grotesque-Mono-Pro-Medium';
  src: url('/fonts/CFBasisMonoPro-Medium.woff2');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Basis-Grotesque-Mono-Pro-Light';
  src: url('/fonts/CFBasisMonoPro-Light.woff2');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Basis-Grotesque-Off-White';
  src: url('/fonts/CFBasis-Off-White.woff2');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

/* https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/ */

h1,
.u-h1,
h2,
.u-h2,
h3,
.u-h3,
h4,
.u-h4,
h5,
.u-h5,
h6,
.u-h6 {
  font-weight: 400;
  color: var(--color-primary);
}

h1,
.u-h1 {
  font-size: clamp(3.375rem, 2.3627rem + 4.3192vw, 6.25rem);
  line-height: var(--line-height, 1.055);
  letter-spacing: normal;
}

h2,
.u-h2 {
  font-size: clamp(2.25rem, 1.2597rem + 4.2254vw, 5.0625rem);
  line-height: var(--line-height, 1.15);
  letter-spacing: normal;
}

h3,
.u-h3 {
  font-size: clamp(2.25rem, 1.8539rem + 1.6901vw, 3.375rem);
  line-height: var(--line-height, 1.1);
  letter-spacing: normal;
}

h4,
.u-h4 {
  font-size: clamp(1.5rem, 1.2359rem + 1.1268vw, 2.25rem);
  line-height: var(--line-height, 1.25);
  letter-spacing: normal;
}

h5,
.u-h5 {
  font-size: clamp(1.25rem, 1.162rem + 0.3756vw, 1.5rem);
  line-height: var(--line-height, 1.25);
  letter-spacing: normal;
}

h6,
.u-h6 {
  font-size: clamp(1rem, 0.912rem + 0.3756vw, 1.25rem);
  line-height: var(--line-height, 1.25);
  letter-spacing: 0.5px;
}

.u-mono-font {
  font-family: 'Basis-Grotesque-Mono-Pro';
}
