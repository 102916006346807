@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.toastMessage {
  @media (min-width: $tablet-min-breakpoint) {
    margin: var(--spacing-xs) 0;
  }
}

.SuccessToast__wrapper {
  & > div {
    @media screen and (max-width: $tablet-min-breakpoint) {
      max-height: 90vh;
    }
  }
}

.SuccessToast__header {
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-m) var(--spacing-l);
  border-bottom: 2px solid var(--grey-page);
  // span {
  //   height: 28px !important;
  // }

  @media screen and (max-width: $tablet-min-breakpoint) {
    // padding: var(--spacing-s);
    padding: var(--spacing-base);
  }
}

.SuccessToast__body {
  padding: var(--spacing-m) var(--spacing-l);
  font-size: rem-calc(22);

  a {
    text-decoration: none;
  }

  p {
    // font-size: rem-calc(22);
    line-height: 125%;
    letter-spacing: 0;
  }

  @media screen and (max-width: $tablet-min-breakpoint) {
    padding: rem-calc(10) rem-calc(20);
    padding-bottom: 0;
    margin-bottom: -1rem;
    font-size: rem-calc(14);
  }
}

.SuccessToast__title {
  padding-bottom: var(--spacing-m);
  margin-bottom: var(--spacing-m);
  border-bottom: 1px solid #f3f3f3;

  font-size: rem-calc(36);
  line-height: rem-calc(44);

  @media screen and (max-width: $tablet-min-breakpoint) {
    padding-bottom: var(--spacing-xs);
    margin-bottom: var(--spacing-xs);

    font-size: rem-calc(24);
    line-height: 125%;

    border-bottom: 1px solid var(--grey-page);
  }
}

.mobile {
  @media (min-width: $mobile-max-breakpoint) {
    display: none;
  }
}

.desktop {
  @media (max-width: $mobile-max-breakpoint) {
    display: none;
  }
}

.SuccessToast_ExpiredSessionImage {
  img {
    max-width: 364px !important;
  }
}
