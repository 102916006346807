@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.addToCartModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;

  z-index: 99999;
  z-index: 999;
  transform: translateY(100%);
  animation: slide-up 600ms cubic-bezier(0.4, 0, 0.2, 1) 30ms forwards;

  @supports (overscroll-behavior: contain) {
    overscroll-behavior: contain;
  }

  &[class*='--before-close'] {
    transition: opacity 500ms;
    opacity: 0;
  }

  &__Content {
    background-color: var(--grey-light);
    width: 100%;
    min-height: 100%;
    max-height: 100vh;
    overflow: unset;

    @media screen and (min-width: $desktop-min-breakpoint) {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    @media screen and (max-width: $mobile-max-breakpoint) {
      padding-bottom: 150px;
    }
  }

  &__ContentContainer {
    overflow: scroll;
    height: 100%;
    max-height: 100vh;

    @media screen and (min-width: $desktop-min-breakpoint) {
      display: contents;
    }
  }
}

.addToCartModalBody {
  overflow: hidden;
  @media screen and (min-width: $desktop-min-breakpoint) {
    overflow: unset;
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

.addTypefaceForm {
  @media screen and (min-width: $desktop-min-breakpoint) {
    padding-right: 54px;
  }
}

.progressBar {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 24px;

  margin-top: 30px;
  margin-bottom: 64px;

  @media screen and (max-width: $mobile-max-breakpoint) {
    column-gap: 16px;
    margin-bottom: 11px;
  }
}

.progressBarMobile {
  display: flex;
  align-items: center;
  line-height: 1.5;
  margin: auto;
  width: 50%;
  margin-bottom: var(--spacing-xl);
  justify-content: center;

  @media screen and (min-width: $tablet-min-breakpoint) {
    display: none;
  }
}

.progressBarItems {
  display: flex;
  align-items: center;
  line-height: 1.5;
  margin: auto;
}

.stepperCircle,
.stepperCircleActive,
.stepperCircleDone {
  text-align: center;
  font-size: rem-calc(16);
  line-height: 1.25;
  border-radius: 50%;

  width: 25px;
  height: 25px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.stepperCircle {
  color: var(--charcoal);
  background: var(--grey-light);
  border: 1px solid var(--charcoal);
}

.stepperCircleDone {
  background: var(--charcoal);
  color: var(--grey-light);
}

.stepperCircleActive {
  width: 30px;
  height: 30px;

  color: var(--grey-light);
  background: var(--charcoal);
  border: 2px solid var(--grey-light);
  box-shadow: 0 0 0 1px var(--charcoal);
}

.stepperLabel {
  color: var(--charcoal);
  margin-left: 8px;

  @media (max-width: $mobile-max-breakpoint) {
    display: none;
  }
}

.stepperLabelMobile {
  display: block;
  color: var(--charcoal);
  font-size: rem-calc(14);

  @media screen and (min-width: $tablet-min-breakpoint) {
    display: none;
  }
}

.modalContent {
  display: flex;
  margin: 0 auto;
  max-width: $row-max-width;
  justify-content: center;
  grid-column-gap: 10px;

  &__Right,
  &__Left {
    width: 50%;
    margin: 50px;
  }

  &__Left {
    max-width: 552px;
  }

  &__Right {
    max-width: 572px;
  }

  ul {
    list-style: none;
    padding: 0;
  }
}

.closeModal {
  padding-top: var(--spacing-xl);
  padding-right: var(--spacing-xl);
  @media (max-width: $mobile-max-breakpoint) {
    padding-top: var(--spacing-m);
    padding-right: 32px;
  }
  display: flex;
  margin: 0 auto;
  max-width: $row-max-width;
  justify-content: flex-end;
}

.hidden {
  display: none;
}

.variantGroupLabel {
  margin-bottom: 20px;
}

.licenceGroup {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 14px;

  @media (max-width: 500px) {
    grid-template-columns: none;
  }
}

.stepContainer {
  max-width: var(--tablet-content-max-width);
}

.stepContent {
  margin-bottom: 40px;
}

.subLabelLicence {
  margin-top: 20px;
  margin-bottom: 20px;
}

.selectedLicence {
  margin-top: 40px;
  margin-bottom: 40px;
  max-width: var(--tablet-content-max-width);
}

.selectedLicenceItem {
  margin-top: 20px;
  --set-input-line-height: 1.25;
}

.licenceHolder {
  max-width: var(--tablet-content-max-width);
  margin-bottom: 40px;
}

.licenceHolderField,
.licenceHolderWeb {
  margin-top: var(--spacing-s);
}

.bottomSpacer {
  padding-top: 200px;
}

.errorModal {
  button {
    margin-top: var(--spacing-m);
  }
}

.maxUsage {
  color: var(--red);

  a {
    color: var(--red);
  }
}
