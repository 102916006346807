@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.panel {
  background-color: var(--white);
  border-radius: var(--border-radius);
}

.panelContent {
  // DEFAULT =  medium .md
  padding: var(--spacing-m);

  @media (max-width: $mobile-max-breakpoint) {
    padding: var(--spacing-s);
  }
}

.panelHeader {
  border-bottom: 1px solid var(--grey-mid);
  display: flex;
  align-items: baseline;

  padding: var(--spacing-m);

  @media (max-width: $mobile-max-breakpoint) {
    padding: var(--spacing-s);
  }

  &Sticky {
    position: sticky;
    top: 100px;
    background-color: var(--white);
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }
}

.xs,
.checkout {
  padding: var(--spacing-base) var(--spacing-m);

  @media (max-width: $mobile-max-breakpoint) {
    padding: var(--spacing-s);
  }

  > h3 {
    font-size: 1rem;
    color: var(--charcoal);
  }
}

.checkout {
  padding-top: 0;
}

.lg {
  padding: var(--spacing-l);

  @media (max-width: $mobile-max-breakpoint) {
    padding: var(--spacing-m);
  }
}

.zero {
  padding: 0;
}
