@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.headerPanel {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 14;

  width: 100%;

  // min-height: 100vh;
  max-height: -moz-available;
  max-height: -webkit-fill-available;

  @media screen and (min-width: $tablet-min-breakpoint) {
    position: absolute;
    min-width: 372px;
    top: -20px;
    right: -20px;
    z-index: 4;
  }

  &__inner {
    display: flex;
    flex-direction: column;

    background-color: var(--white);
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    margin-top: 72px;
    margin-bottom: 80px;
    padding-top: calc(var(--spacing-xs) + var(--ticker-height, 0px));

    @media screen and (max-width: $mobile-max-breakpoint) {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        height: 72px;
        width: 100%;
        background-color: var(--white);
      }
    }

    @media screen and (min-width: $tablet-min-breakpoint) {
      border: 1px solid var(--grey-primary);
      border-radius: var(--border-radius);
      margin-top: 0;
      padding-top: 62px;
    }

    @media screen and (max-height: 630px) {
      max-height: calc(100vh - 72px - 20px);
      overflow: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
