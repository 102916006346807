@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
@import 'reboot';
@import 'colours';
@import 'variables';
@import 'typography';
@import 'themes';

html {
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

body {
  font-family: $font-family;
  --font: #{$font-family};
  letter-spacing: calc((0.5 / 16) * 1em);
  line-height: var(--line-height, calc(20 / 16));
}

input,
button,
select,
optgroup,
textarea {
  font-family: var(--font);
  font-size: rem-calc(20);
}

p {
  font-family: var(--font);
  margin: 0;
  padding: 0;
  color: var(--color-primary);
}

a {
  color: var(--color-primary);
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font);
  margin: 0;
  padding: 0;
  color: var(--color-primary);
}

h5,
h6 {
  letter-spacing: 0;
}

button {
  margin: 0;
}

img {
  height: auto;
}

dd,
dt {
  font-weight: 400;
  margin: 0;
}

// Remove default list padding
ol {
  padding-left: 0;
  margin-left: 1em;
}

*:focus,
*:focus-visible {
  outline: 2px dashed var(--leroy);
  outline-offset: 2px;
}

*:focus:not(:focus-visible) {
  outline: 0;
}

input:focus,
input:focus-visible,
select:focus,
select:focus-visible,
textarea:focus,
textarea:focus-visible {
  outline: 1px solid var(--input-focus-border-color);
  outline-offset: -2px;
}

input:focus:not(:focus-visible),
select:focus:not(:focus-visible),
textarea:focus:not(:focus-visible) {
  outline: 0;
}

.mobile-overflow-hidden {
  @media screen and (max-width: $tablet-max-breakpoint) {
    overflow: hidden;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.fourOhFour {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 1rem;

  > div {
    margin-bottom: 1rem;
  }
}

.u-hidden-mobile {
  @media screen and (max-width: $mobile-max-breakpoint) {
    display: none;
  }
}

.u-hidden-tablet {
  @media screen and (min-width: $tablet-min-breakpoint) and (max-width: $tablet-max-breakpoint) {
    display: none;
  }
}

.u-hidden-desktop {
  @media screen and (min-width: $desktop-min-breakpoint) {
    display: none;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.checkout-nav {
  display: flex;
  width: 100%;
  justify-content: space-between;

  & > button:last-of-type {
    margin-left: auto;
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Content {
  border: none;

  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

/*
 Alert Boxes
*/

.Alert {
  border: 1px solid var(--charcoal);
  box-sizing: border-box;
  border-radius: 16px;
  margin: 16px 0;
  padding: 16px 16px 16px 8px;

  font-weight: 400;
  font-size: rem-calc(14);
  line-height: 1.3;
  letter-spacing: 0.5px;

  display: flex;
  align-items: center;

  span {
    margin-right: 10px;
  }
}

.AlertError {
  --icon-stroke-color: var(--error);
  --icon-fill-color: transparent;
  color: var(--error);
  border-color: var(--error);
  background-color: var(--white);
}

.skip-to-content-link {
  background: var(--black);
  color: var(--white);
  display: flex;
  align-items: center;
  height: 50px;
  left: 50%;
  padding: 8px 20px;
  position: absolute;
  transform: translateY(-105%) translateX(-50%);
  transition: transform 0.3s;
  z-index: 9;
}

.skip-to-content-link:focus {
  transform: translateY(0%) translateX(-50%);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

@media (prefers-reduced-motion) {
  @media (prefers-reduced-motion: reduce) {
    *,
    ::before,
    ::after {
      animation-delay: -1ms !important;
      animation-duration: 1ms !important;
      animation-iteration-count: 1 !important;
      background-attachment: initial !important;
      scroll-behavior: auto !important;
      transition-duration: 0s !important;
      transition-delay: 0s !important;
    }
  }
}

.with-scrollbar {
  overflow-y: scroll;
  scrollbar-width: auto;
  scrollbar-color: var(--charcoal);

  &::-webkit-scrollbar {
    width: var(--spacing-xs);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--charcoal);
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.with-vertical-scrollbar {
  overflow-x: scroll;
  scrollbar-width: auto;
  scrollbar-color: var(--charcoal);
  padding-bottom: var(--spacing-xs);

  &::-webkit-scrollbar {
    height: var(--spacing-xs);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--charcoal);
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
