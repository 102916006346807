@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.summary {
  border-radius: var(--border-radius);
  overflow: hidden;
  @media (min-width: $desktop-min-breakpoint) {
    position: sticky;
    top: 100px;
  }
}

.summary__header {
  display: flex;
  padding: var(--spacing-base) var(--spacing-m) var(--spacing-base) var(--spacing-m);
  justify-content: space-between;
  align-items: center;

  background-color: var(--white);
  border-bottom: 1px solid var(--grey-mid);
  color: var(--charcoal);
}

.summary__content {
  background-color: var(--white);
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  padding: var(--spacing-s);
  display: flex;
  flex-direction: column;

  @media (min-width: $desktop-min-breakpoint) {
    padding: 0 var(--spacing-m) var(--spacing-l) var(--spacing-m);
  }
}

@media (max-width: $tablet-max-breakpoint) {
  .share {
    margin-right: var(--spacing-xl);
  }
  .summary__content {
    border-radius: 0;
  }
}

.summary__title {
  font-size: rem-calc(34);
}

.summary__list {
  padding: 0;
  margin: 0;
  list-style: none;

  li:last-child {
    border-bottom: none;
  }
}

.summary__list-wrapper-item {
  border-bottom: 1px solid var(--grey-mid);
}

.summary__list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: var(--spacing-s);
  padding-bottom: var(--spacing-s);
}

.summary__list-item__border {
  border-bottom: 1px solid var(--grey-mid);
}

.customFont {
  font-family: var(--custom-font);
  font-size: rem-calc(32);
  line-height: 1;
  border: 1px solid var(--grey);
  border-radius: 10px;
  padding: 8px 10px;
  margin-right: 14px;
}

.name {
  flex-grow: 1;
  margin-right: var(--spacing-m);
}

.price {
  font-family: Basis-Grotesque-Mono-Pro;
  font-size: rem-calc(16);

  & + button {
    margin-left: 10px;
  }
}

.subTotal {
  padding-top: var(--spacing-s);
  border-top: 1px solid var(--grey-mid);

  .subTotalValue {
    font-family: Basis-Grotesque-Mono-Pro;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 50px;
}

.emptyGrow {
  flex-grow: 1;
}

.total {
  padding-top: rem-calc(18px);
  border-top: 1px solid var(--grey-mid);
  display: flex;
  height: auto;
  align-items: center;
  justify-content: space-between;
}

.totalValue {
  font-family: Basis-Grotesque-Mono-Pro;
}

.fontFamilyGroup {
  flex-basis: 100%;
  max-height: 222px;

  margin-top: var(--spacing-base);
  margin-bottom: var(--spacing-base);
  margin-right: calc(var(--spacing-base) * -1);

  @media (max-width: $tablet-max-breakpoint) {
    margin-right: 0;
  }
}

.fontFamilyGroup__List {
  padding: 0;
  margin: 0;
  list-style: none;

  line-height: 1.5;
  text-transform: capitalize;

  &Item:not(:last-of-type) {
    &::after {
      margin-right: 5px;
    }
  }
}
