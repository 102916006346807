@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.ModalContent__inner {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 auto;
  padding: var(--spacing-m);
  // border: 1px solid black;
  border-radius: var(--border-radius);
  background-color: var(--white);

  max-width: 800px;
  min-width: 335px;
  max-height: calc(100% - 50px);

  overflow: scroll;

  // Hide scrollbar
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: $mobile-max-breakpoint) {
    max-width: 335px;
    min-width: unset;
    align-items: flex-start;
  }

  &--top {
    margin-top: var(--header-height);
  }

  &--medium {
    @media screen and (min-width: $tablet-min-breakpoint) {
      height: 370px;
    }
  }

  &--basic {
    flex-direction: column;
    max-width: 984px;
    padding: 0;

    @media screen and (max-width: $mobile-max-breakpoint) {
      max-width: 327px;
    }
  }

  &--twoColumn {
    height: 492px;
    width: 984px;
    max-width: 984px;
    padding: 0;

    flex-direction: column;

    @media screen and (max-width: $tablet-max-breakpoint) {
      width: 80vw;
      max-height: 60vh;
      height: auto;
    }

    @media screen and (max-width: $tablet-min-breakpoint) {
      max-height: 90vh;
      height: auto;
      width: 260px;

      flex-direction: row;
    }
  }

  &--autoHeight {
    @media screen and (max-width: $tablet-max-breakpoint) {
      max-height: 70vh;
      height: auto;
    }
  }
}

.ModalContent__header {
  width: 100%;
  border-bottom: 2px solid var(--grey-page);
  padding: var(--spacing-base) var(--spacing-m);

  @media screen and (min-width: $tablet-min-breakpoint) {
    padding: var(--spacing-m) var(--spacing-l);
  }

  h3 {
    font-size: rem-calc(24);
    line-height: rem-calc(30);

    span {
      font-size: rem-calc(16);
      text-transform: uppercase;
    }

    @media screen and (min-width: $tablet-min-breakpoint) {
      font-size: rem-calc(36);
      line-height: rem-calc(45);
    }
  }
}

.ModalContent__actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  order: 3;
  flex-wrap: wrap;
  border-top: 2px solid var(--grey-page);

  @media screen and (min-width: $tablet-min-breakpoint) {
    flex-direction: row;
  }

  @media screen and (min-width: $desktop-min-breakpoint) {
    width: auto;
    order: unset;
    margin: 0 20px 0 0;
  }

  &--basic {
    margin: 0;
    padding: var(--spacing-m) var(--spacing-l);

    input {
      width: 100%;
    }

    button: {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  &--twoColumn {
    padding: var(--spacing-l) var(--spacing-l);
    border: none;

    @media screen and (max-width: $tablet-min-breakpoint) {
      padding: rem-calc(20);
      gap: rem-calc(8);
    }
  }
}

.ModalContent__MessageBody {
  padding: var(--spacing-base) var(--spacing-m);

  @media screen and (min-width: $tablet-min-breakpoint) {
    padding: var(--spacing-m) var(--spacing-l);
  }

  &--borderBottom {
    border-bottom: 2px solid var(--grey-page);
  }

  p {
    font-size: rem-calc(14);

    @media screen and (min-width: $tablet-min-breakpoint) {
      font-size: rem-calc(22);
      line-height: rem-calc(25);
    }
  }
}

.ModalContent__MessageBodyColumn {
  max-height: 469px;
  padding: 0 var(--spacing-m);

  &--withScroll {
    // compensate scrollbar position
    margin-right: calc(calc(var(--spacing-m) / 2) - calc(var(--spacing-xs) / 2));
    padding-right: calc(calc(var(--spacing-m) / 2) + calc(var(--spacing-xs) / 2));

    &::-webkit-scrollbar {
      width: var(--spacing-xs);
    }
  }
}

.ModalContent__message {
  flex: 1;
  font-size: rem-calc(20);
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-base);
  max-width: 100%;

  > * {
    width: 100%;
  }

  @media screen and (max-width: $mobile-max-breakpoint) {
    font-size: rem-calc(16);
  }

  @media screen and (min-width: $tablet-min-breakpoint) {
    padding-right: 25px;
  }

  &--medium {
    @media screen and (min-width: $desktop-min-breakpoint) {
      margin-top: 56px;
    }
  }

  &--basic {
    padding: 0;

    gap: 0;
  }

  &--twoColumn {
    width: 50%;
    height: 100%;
    padding: 0;

    flex: initial;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;

    @media screen and (max-width: $tablet-min-breakpoint) {
      width: 100%;
      height: auto;
    }
  }

  p {
    font-size: rem-calc(14);

    @media screen and (min-width: $tablet-min-breakpoint) {
      font-size: rem-calc(22);
      & > a {
        span {
          text-decoration: underline;
          display: inline-block;
          vertical-align: initial;
        }
      }
    }
  }
}

.MessageBodyBlok {
  padding: var(--spacing-base) 0;

  &:not(:first-child) {
    border-top: 2px solid var(--grey-page);
  }

  @media screen and (min-width: $tablet-min-breakpoint) {
    padding: var(--spacing-m) 0;
  }
}

.MessageBodyBlok__Title {
  padding-bottom: var(--spacing-base);
  border-bottom: 2px solid var(--grey-page);
  margin-bottom: var(--spacing-base);

  @media screen and (min-width: $tablet-min-breakpoint) {
    padding-bottom: var(--spacing-m);
    border-bottom: 2px solid var(--grey-page);
    margin-bottom: var(--spacing-m);
  }
}

.MessageBodyBlok__Body {
  font-size: rem-calc(22);
  line-height: 1.25;

  p {
    line-height: 1.25;

    span {
      font-size: rem-calc(22);
    }
  }
}

.ModalContent__inner {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 auto;
  padding: var(--spacing-m);
  // border: 1px solid black;
  border-radius: var(--border-radius);
  background-color: var(--white);

  max-width: 800px;
  min-width: 335px;
  max-height: calc(100% - 50px);

  overflow: scroll;

  // Hide scrollbar
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: $mobile-max-breakpoint) {
    max-width: 335px;
    min-width: unset;
    align-items: flex-start;
  }

  &--top {
    margin-top: var(--header-height);
  }

  &--medium {
    @media screen and (min-width: $tablet-min-breakpoint) {
      height: 370px;
    }
  }

  &--basic {
    flex-direction: column;
    max-width: 984px;
    padding: 0;

    @media screen and (max-width: $mobile-max-breakpoint) {
      max-width: 327px;
    }
  }

  &--twoColumn {
    height: 492px;
    width: 984px;
    max-width: 984px;
    padding: 0;

    flex-direction: column;

    @media screen and (max-width: $tablet-max-breakpoint) {
      width: 80vw;
      max-height: 60vh;
      height: 500px;
    }

    @media screen and (max-width: $tablet-min-breakpoint) {
      max-height: 90vh;
      height: auto;
      width: 260px;

      flex-direction: row;
    }
  }
}

.ModalContent__Icon {
  padding: 10px;

  svg {
    max-width: 100%;
  }

  @media screen and (max-width: $mobile-max-breakpoint) {
    order: 3;
  }

  @media screen and (min-width: $desktop-min-breakpoint) {
    min-width: 330px;
    min-height: 100%;
    display: grid;
    place-content: center;
  }

  &--twoColumn {
    width: 50%;
    height: 100%;
    padding: var(--spacing-l);

    display: flex;
    justify-content: center;

    background-color: var(--grey-page);

    @media screen and (max-width: $tablet-min-breakpoint) {
      width: 100%;
      height: auto;
      min-height: 252px;
      padding: rem-calc(20);
    }
  }
}

.ModalContent__Message {
  flex: 1;
  font-size: rem-calc(20);
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-base);

  > * {
    width: 100%;
  }

  @media screen and (max-width: $mobile-max-breakpoint) {
    font-size: rem-calc(16);
  }

  @media screen and (min-width: $tablet-min-breakpoint) {
    padding-right: 25px;
  }

  &--medium {
    @media screen and (min-width: $desktop-min-breakpoint) {
      margin-top: 56px;
    }
  }

  &--basic {
    padding: 0;

    gap: 0;
  }

  &--twoColumn {
    width: 50%;
    height: 100%;
    padding: 0;

    flex: initial;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;

    @media screen and (max-width: $tablet-min-breakpoint) {
      width: 100%;
      height: auto;
    }
  }

  p {
    font-size: rem-calc(14);

    @media screen and (min-width: $tablet-min-breakpoint) {
      font-size: rem-calc(22);
      & > a {
        span {
          text-decoration: underline;
          display: inline-block;
          vertical-align: initial;
        }
      }
    }
  }
}
