@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.infoModalContainer {
  font-size: rem-calc(14);
  line-height: 120%;
  letter-spacing: 0.5px;
  color: var(--charcoal);
  margin: var(--spacing-m) 0 40px;
  display: block;

  button {
    text-align: left;
    font-size: rem-calc(14);
    color: var(--color-primary);

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      top: calc(100% + 2px);
      background-color: currentColor;
    }
  }

  p:first-of-type {
    margin-bottom: var(--spacing-s);
  }
}

.infoModalArticle {
  padding-top: var(--spacing-s);
  border-top: 1px solid var(--grey-mid);

  h5 {
    margin-bottom: var(--spacing-s);
  }

  p:last-child {
    padding: 0;
  }

  @media (min-width: $tablet-min-breakpoint) {
    padding-top: var(--spacing-m);

    h5 {
      margin-bottom: var(--spacing-m);
    }
  }
}
.inlineButton {
  display: inline-block;
}

.paragraph {
  padding-bottom: var(--spacing-m);
}

.paragraph,
.introParagraph {
  font-size: rem-calc(20);
  line-height: 1.4;
}

.content {
  padding: var(--spacing-m);
}

.introParagraph {
  margin-top: var(--spacing-l);
}

.scrollableContent {
  max-height: 600px;
  overflow-y: scroll;
  padding-right: var(--spacing-m);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);

  @media (min-width: $tablet-min-breakpoint) {
    gap: var(--spacing-m);
  }
}

.downloadSection {
  p {
    padding-bottom: var(--spacing-xs);
  }

  &__fileExtensions {
    font-size: rem-calc(20);
    line-height: 1.25;
    padding-bottom: var(--spacing-base);
  }

  &__downloadButton {
    margin-top: var(--spacing-xs);
  }
}

.learnMore {
  margin-top: var(--spacing-s);

  @media (min-width: $tablet-min-breakpoint) {
    margin-top: var(--spacing-m);
  }
}

.infoModalContent {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
  max-width: 780px;

  @media (min-width: $tablet-min-breakpoint) {
    gap: var(--spacing-m);
  }
}

.proHeader {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--spacing-s);
  gap: var(--spacing-xs);
  flex-wrap: wrap;
  flex-direction: column;

  h5,
  span {
    margin: 0;
  }

  @media (min-width: $tablet-min-breakpoint) {
    flex-direction: row;
    align-items: center;
    margin-bottom: var(--spacing-m);
    gap: var(--spacing-base);
  }
}

.LicenseModalHeader {
  display: flex;
  justify-content: space-between;

  h5 {
    font-size: rem-calc(24);
    line-height: rem-calc(30);
    font-weight: 400;

    span {
      display: inline-block;
    }
  }

  p {
    align-items: center;
    display: none;

    span {
      display: flex;
      align-items: center;
      margin: 0;
      font-size: rem-calc(12);
      line-height: rem-calc(16);

      text-align: center;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }
  }

  @media (min-width: $tablet-min-breakpoint) {
    h5 {
      font-size: rem-calc(36);
      line-height: rem-calc(45);
      span {
        display: none;
      }
    }

    p {
      display: flex;
    }
  }
}

.fileTypes {
  font-family: 'Basis-Grotesque-Mono-Pro';

  @media (max-width: $tablet-min-breakpoint) {
    font-size: rem-calc(16) !important;
  }
}

.LicenseModalButton {
  & > :first-child {
    text-decoration: none;
    font-size: rem-calc(16);

    @media (max-width: 350px) {
      white-space: initial;
    }
  }
}

.ScrollCarousel {
  & > div {
    height: 482px;
  }

  @media (max-height: 670px) {
    & > div {
      max-height: 270px;
    }
  }
}

.LicenseVersionModal__ScrollCarousel {
  & > div {
    height: 482px;
  }

  & > :last-child {
    @media (max-height: 670px) {
      max-height: 325px;
    }
  }
}
