@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.CartDiscount {
  margin-top: 40px;
}

.FormDescription {
  margin-bottom: var(--spacing-base);
}

.DiscountField {
  display: flex;
  gap: var(--spacing-m);
  justify-content: space-between;

  @media (max-width: $mobile-max-breakpoint) {
    flex-direction: column;
  }
}

.Input {
  font-family: 'Basis-Grotesque-Mono-Pro';
  max-width: 283.5px;
  border-color: var(--charcoal);

  @media (max-width: $mobile-max-breakpoint) {
    max-width: 100%;
  }
}
