@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.CloseButton {
  position: absolute;
  top: var(--spacing-base);
  right: var(--spacing-base);
  padding: 0;
  font-size: rem-calc(24);
  line-height: rem-calc(24);
  background-color: var(--white);
  border: none;
  cursor: pointer;

  p {
    width: auto;
    height: auto;
    line-height: rem-calc(30);

    @media screen and (min-width: $tablet-min-breakpoint) {
      // font-size: rem-calc(36);
      line-height: rem-calc(45);
    }

    &::after {
      content: '';
      position: absolute;
      z-index: 0;

      top: 50%;
      left: 50%;

      width: 30px;
      height: 30px;

      transform: translate(-50%, -50%) scale(0);
      opacity: 0;

      background-color: var(--charcoal);

      display: block;
      border-radius: 50%;
      transition: all 0.35s var(--transiton-timing-ease);
    }
  }

  @media screen and (min-width: $tablet-min-breakpoint) {
    top: var(--spacing-m);
    right: var(--spacing-l);
  }

  &--basic {
    line-height: rem-calc(36);
  }

  &--twoColumn {
    top: var(--spacing-s);

    @media screen and (min-width: $tablet-min-breakpoint) {
      top: var(--spacing-s);
      line-height: rem-calc(30);
    }
  }

  &:hover {
    p {
      color: var(--white);
      span {
        z-index: 1;
      }
    }
    p::after {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
      transition: all 0.2s var(--transiton-timing-ease);
    }
  }
}

.CloseButton__closeIcon {
  position: relative;
  display: block;
}

.CloseButton__buttonInner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
}
