@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.header {
  --header-spacing: 16px;
  --fixed-nav-link-color: var(--black);
  --logo-hover-bg: var(--charcoal);
  --logo-hover-svg: var(--white);

  color: var(--color-primary);

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 15;

  transition: all 0.2s var(--transiton-timing-ease);

  display: flex;
  padding: 0 0 var(--header-spacing);
  flex-wrap: wrap;

  &__content {
    padding-bottom: var(--header-spacing);
    width: 100%;
  }

  & > div:last-of-type {
    position: relative;
    z-index: 5;
  }

  @media screen and (min-width: $tablet-min-breakpoint) {
    --header-spacing: 20px;
    --icon-width: 24px;
    --icon-height: 24px;

    --arrow-icon-width: 18px;
    --arrow-icon-height: 18px;

    & > div:last-of-type > div {
      min-height: 50px;
    }
  }

  @media (min-width: $desktop-min-breakpoint) {
    --icon-width: 32px;
    --icon-height: 32px;

    --arrow-icon-width: 18px;
    --arrow-icon-height: 18px;
    --set-dropdown-height: 54px;

    & > div:last-of-type > div {
      min-height: 54px;
    }
  }
}

.breadcrumbArrow {
  margin: 0 22px 0 22px;
  --icon-width: var(--arrow-icon-width);
  --icon-height: var(--arrow-icon-height);

  @media screen and (max-width: $mobile-max-breakpoint) {
    display: none;
  }

  @media screen and (min-width: $desktop-min-breakpoint) {
    margin: auto;
    left: 10px;
    position: relative;
  }
}

.breadcrumbMobile {
  display: contents;
  @media (min-width: $desktop-min-breakpoint) {
    display: none;
  }
}

.breadcrumbDesktop {
  display: flex;
  align-items: stretch;
  gap: var(--spacing-m);

  button {
    --button-l-padding: 8px var(--spacing-m);
    min-height: 100%;
    font-size: clamp(1rem, 0.912rem + 0.3756vw, 1.25rem);
  }

  @media (max-width: $tablet-max-breakpoint) {
    display: none;
  }
}

.logoNav {
  display: flex;
  align-items: center;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
  }

  &_menuIsOpen {
    .logoLabel {
      @media (max-width: $mobile-max-breakpoint) {
        display: none;
      }
    }

    @media (min-width: $tablet-min-breakpoint) and (max-width: $tablet-max-breakpoint) {
      z-index: 5;
    }
  }
}

.logoLabel {
  display: none;
  margin-left: var(--spacing-s);

  @media (max-width: $mobile-max-breakpoint) {
    font-size: rem-calc(16);
  }

  @media screen and (min-width: $desktop-min-breakpoint) {
    display: block;
    margin-left: 30px;
  }
}

.logoArrow {
  svg {
    margin: 0 0 0 28px;

    stroke: none;
    fill: var(--header-color);

    @media screen and (max-width: $mobile-max-breakpoint) {
      display: none;
    }
  }
}

.logo {
  span {
    position: relative;
    width: 26px !important;
    height: 33px !important;
    display: inline-flex;

    svg {
      position: relative;
      transition: fill 0.2s 0.1s var(--transiton-timing-ease);
      z-index: 2;

      width: 100%;
      height: 100%;
      min-width: unset;
      min-height: unset;

      @media screen and (max-width: $mobile-max-breakpoint) {
        height: 75%;
        top: 3px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      z-index: 1;

      top: 50%;
      left: 50%;

      width: 45px;
      height: 45px;

      transform: translate(-50%, -50%) scale(0);
      opacity: 0;

      background-color: var(--logo-hover-bg);

      display: block;
      border-radius: 50%;
      transition: all 0.35s var(--transiton-timing-ease);
    }
  }

  &:hover {
    svg {
      --icon-color-internal: var(--logo-hover-svg);
      transition: fill 0.2s 0.2s var(--transiton-timing-ease);
    }

    span::after {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
      transition: all 0.2s var(--transiton-timing-ease);
    }
  }
}

.navtopIcons {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  position: relative;
}

.topIcon {
  display: flex;
  align-items: center;

  margin-left: 8px;
  position: relative;

  > button,
  > a {
    position: relative;
    z-index: 8;
  }

  @media screen and (min-width: $desktop-min-breakpoint) {
    margin-left: 16px;
  }
}

.headerBorder {
  position: absolute;
  bottom: calc(var(--header-spacing) * -1);
  left: 0;
  width: 100%;
  height: 1px;
  transition: background-color 0.2s;

  // Unavoidable, and not worth refactoring Grid component to work around this
  > div > div {
    width: 100% !important;
    margin: 0 auto !important;
  }

  hr {
    width: 100%;
    max-width: var(--page-width);
    border: none;
    border-top: 1px solid var(--grey);
    margin: 0 auto;
  }
}

.showFixedNav {
  transform: translateY(0);
  transition: transform 0.5s;
}

.basketCount {
  color: var(--header-icon-color);
  font-family: 'Basis-Grotesque-Mono-Pro';
}

/* DESKTOP */
@media (min-width: $desktop-min-breakpoint) {
  .wrapper {
    display: block;
  }
}

.headerFixed {
  --header-color: var(--black);
  --header-spacing: 24px;
  --header-icon-color: var(--black);
  --color-primary: var(--black);
  --color-background: var(--white);
  --logo-hover-bg: var(--grey-mid);
  --logo-hover-svg: unset;

  background: var(--white);

  .headerBorder hr {
    border-color: transparent;
  }
}

.headerMenuOpen {
  --header-icon-color: var(--black);
}

.accountName {
  z-index: 100;
}
