@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.table {
  --row-padding: var(--set-table-row-padding, 20px);
  --border-color: var(--set-table-border-color, var(--black));

  width: 100%;
  table-layout: fixed;
}

.header {
  th,
  td {
    padding-bottom: var(--row-padding);
  }
  border-bottom: 1px solid var(--border-color);
}

.row {
  th,
  td {
    padding-top: var(--row-padding);
  }

  &:not(:last-child) {
    th,
    td {
      padding-bottom: var(--row-padding);
    }
    border-bottom: 1px solid var(--border-color);
  }
}
