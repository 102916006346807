@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.DropdownContainer {
  position: relative;

  width: var(--set-dropdown-width, var(--dropdown-width));
  text-transform: capitalize;
}

.DropdownContainerWide {
  --dropdown-width: 320px;

  @media screen and (min-width: $desktop-min-breakpoint) {
    --dropdown-width: 360px;
  }
}

.DropdownContainerTransparent {
  --dropdown-background-color: transparent;
}

.DropdownContainerInline {
  display: inline-block;
}

.Button {
  display: flex;
  align-items: center;
  appearance: none;
  outline: none;
  padding: var(--spacing-base) var(--spacing-m);
  color: var(--dropdown-color);
  background: var(--dropdown-background-color);
  border: 1px solid var(--dropdown-color);
  border-radius: var(--input-radius);
  font-size: rem-calc(20);
  line-height: rem-calc(30);
  font-weight: 300;
  letter-spacing: var(--input-letterspacing);
  cursor: pointer;
  transition: all 0.2s ease 0.05s;
  height: var(--set-dropdown-height, initial);

  &:focus-visible {
    outline: 1px dashed var(--checkbox-border-color);
    outline-offset: 2px;
  }

  &[aria-expanded='true'] {
    border-color: transparent;
    --dropdown-color: var(--black);
    --dropdown-background-color: transparent;
    --icon-color: var(--dropdown-color) !important;
    background-color: var(--dropdown-background-color);

    &:hover {
      background-color: transparent;
      border-color: transparent;
    }
  }
}

.Button:disabled {
  filter: opacity(0.3);
}

.ButtonSmall,
.ButtonWide {
  font-size: rem-calc(16);
  line-height: rem-calc(20);
  padding: 10px var(--spacing-m);
}

.ButtonWide {
  width: 100%;
}

.Button:hover,
.DropdownIsActive {
  background: var(--dropdown-active-background-color);
  border: 1px solid var(--dropdown-active-border-color);
  --dropdown-color: var(--black);
  --header-color: var(--black);
}

// @TODO: use 'Button with icon' component and bin those spans
.ButtonText {
  flex-grow: 1;
  text-align: left;
  text-transform: capitalize;
}

.ButtonIcon {
  margin-left: 8px;
  --icon-width: var(--arrow-icon-width, --icon-width);
  --icon-height: var(--arrow-icon-height, --icon-height);
}

.ButtonFullWidth {
  width: 100%;
}

.DropdownListWrapper {
  position: absolute;
  left: 0;
  top: 15px;
  border-radius: var(--border-radius);
  overflow: hidden;
  width: 100%;
  background: var(--dropdown-list-background);
  border: 1px solid var(--dropdown-border-color);
  visibility: hidden;
  opacity: 0;
  min-width: fit-content;
  width: 100%;
  z-index: 5;

  top: -1px;
  transform: none;

  button:not(.DropdownItem) {
    border: 0;
  }
}

.DropdownListWrapperFilled {
  --dropdown-background-color: var(--color-background);
  --dropdown-color: var(--color-primary);
}

.DropdownListWrapperSmall,
.DropdownListWrapperWide {
  background: var(--dropdown-background-color);
}

.DropdownListWrapperUpwards {
  bottom: 100%;
  top: auto;
  margin-top: 0;
}

.DropdownList {
  max-height: 250px;
  padding: 0;
  margin: var(--spacing-xs) var(--spacing-m);
  transition: transform 0.2s ease, opacity 0.2s ease, visibility 0s linear 0.2s;
  will-change: transform;
  list-style: none;
  overflow-y: scroll;
  overscroll-behavior-y: contain;
  scrollbar-width: thin;
  scrollbar-color: var(--dropdown-color) var(--dropdown-background-color);
  white-space: nowrap;

  border-top: 1px solid var(--grey-primary);
  padding-top: var(--spacing-xs);

  // Hide scrollbar
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.DropdownListSmall,
.DropdownListWide {
  max-height: 180px;
  background: var(--dropdown-background-color);
}

.DropdownList::-webkit-scrollbar {
  width: 11px;
}

.DropdownList::-webkit-scrollbar-track {
  background: var(--grey-light);
}

.DropdownList::-webkit-scrollbar-thumb {
  background-color: var(--dropdown-color);
  border-radius: 6px;
  border: 3px solid var(--grey-light);
}

.DropdownListSmall {
  padding-top: 4px;
  margin-top: 0;
}

.DropdownListSmall::-webkit-scrollbar-track,
.DropdownListWide::-webkit-scrollbar-track {
  background: var(--grey-white);
}

.DropdownListSmall::-webkit-scrollbar-thumb,
.DropdownListWide::-webkit-scrollbar-thumb {
  border: 3px solid var(--dropdown-background-color);
}

.DropdownListWide {
  width: 100%;
}

.DropdownListVisible {
  transition: opacity 0.2s ease, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;

  &.DropdownListWrapperUpwards {
    transform: translateY(-0.3rem);
  }
}

.TypefacesDropdown {
  width: 100%;

  .Button {
    font-size: clamp(1rem, 0.912rem + 0.3756vw, 1.25rem);
  }

  .DropdownList {
    margin: 0;
    padding: 8px var(--spacing-m);
    padding-right: 16px;

    li {
      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
    }

    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  @media (min-width: $tablet-min-breakpoint) {
    min-width: 175px;
  }

  @media (min-width: $desktop-min-breakpoint) {
    min-width: 200px;
  }

  @media (min-width: $desktop-large-min-breakpoint) {
    min-width: 300px;
  }
}

.DropdownItem {
  &__align-left {
    * {
      text-align: left;
    }
  }
}
