@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.checkoutFormMain {
  position: relative;
  padding-top: 20px;
  background-color: var(--grey-light);
  height: 100%;
  padding-bottom: 140px;
  padding-top: 90px;
  @media screen and (min-width: $tablet-max-breakpoint) {
    padding-top: 40px;
  }
}
.checkoutStep {
  margin-bottom: 40px;
  @media screen and (min-width: $tablet-max-breakpoint) {
    margin-bottom: 60px;
  }
}
.continue {
  margin-left: 10px;
}
.checkoutLayout {
  padding: 20px;
  background-color: var(--grey-light);
}
.checkoutSteps {
  display: flex;
  margin: auto;
  width: 50%;
  justify-content: space-evenly;
}
.stepper {
  display: flex;
  color: var(--charcoal);
}
.stepperCircle {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 2px;
  background: #fff;
  border: 1px solid var(--charcoal);
  color: #000;
  text-align: center;
  font-size: rem-calc(14);
  line-height: 1.3;
  margin-right: 10px;
}

.stepperCircleActive {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 2px;
  background: var(--charcoal);
  border: 1px solid var(--charcoal);
  color: var(--white);
  text-align: center;
  font-size: rem-calc(14);
  line-height: 1;
  border: 2px solid var(--grey-light);
  box-shadow: 0 0 2px var(--black);
  margin-right: 10px;
}

.stepperCircleDone {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 2px;
  background: var(--charcoal);
  border: 1px solid var(--charcoal);
  color: var(--white);
  text-align: center;
  font-size: rem-calc(14);
  line-height: 1.3;
  margin-right: 10px;
}
.checkoutGrid {
  margin-top: 30px;
}
.confirmTitle {
  font-size: rem-calc(24);
  margin-bottom: 20px;
}
.confirmSubTitle {
  font-size: rem-calc(16);
  color: var(--charcoal);
  margin-bottom: 40px;
}
.checkoutCartLayout {
  width: 570px;
  background-color: var(--white);
  border-radius: var(--border-radius);
}

.checkoutLoginEmail {
  margin-bottom: 20px;
}
.checkoutAlreadyLoggedinButtonGroup {
  display: flex;
  margin-top: 40px;
}
.checkoutLoginButtonGroup {
  display: flex;
  margin-top: 20px;
}
.checkoutLoginForgotPassword {
  margin-top: 20px;
}

.checkoutRegisterFields {
  margin-bottom: 26px;
}

.checkoutForgotPasswordFields {
  margin-bottom: 26px;
}

.checkoutRegisterButtonGroup {
  display: flex;
  margin-top: 20px;
}

.checkoutVAT {
  margin-bottom: var(--spacing-m);
}

.stepNavigation {
  display: flex;
  justify-content: space-between;
  bottom: 0px;
  padding: 20px;
  position: sticky;
  height: 80px;
  background-color: var(--grey-light);
  border-top: 1px solid var(--white);
  margin-top: 0px;
}

.vatStep {
  height: 100vh;
}

.stepNavigationBack {
  display: flex;
  align-items: center;
}

.stepNavigationBackLabel {
  margin-left: 4px;
}

.stepNavigationContinueLabel {
  margin-right: 4px;
}

.stepNavigationContinue {
  display: flex;
  align-items: center;
}

.AddressDisplay {
  margin-bottom: var(--spacing-xl);
}

.AddressDisplay__header {
  padding: 14px var(--spacing-l);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--grey-mid);
}

.AddressDisplay__body {
  padding: 0 var(--spacing-l) var(--spacing-s) var(--spacing-l);

  &Row {
    display: flex;
    padding: var(--spacing-base) 0;
    border-bottom: 1px solid var(--grey-mid);

    &:last-child {
      border-bottom: none;
    }

    address {
      margin: 0;
    }
  }

  &Label {
    flex: 100px 0 0;
    margin: 0 20px 0 0;
    font-size: rem-calc(14);

    @media screen and (min-width: $tablet-max-breakpoint) {
      flex-basis: 180px;
      margin: 0 10px 0 0;
    }
  }
}
