@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
/*    Variables
 *
 *  --set-card-padding
 *  --set-card-border
 *  --set-card-border-radius
 *  --set-card-background
 *
 *  --set-card-width
 *  --set-card-width-tablet
 *  --set-card-width-mobile
 *
 *  --set-card-height
 *  --set-card-height-tablet
 *  --set-card-height-mobile
 *
 *  --set-card-margin-bottom
 *
 *  --set-card-row-padding
 *  --set-card-row-padding-last
 *
 */

@mixin layout-flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > * {
    flex: 0 0 auto;
  }
}

@mixin layout-flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  & > * {
    flex: 0 0 auto;
  }
}

@mixin grow-n($n, $align: center, $parentPosition: relative) {
  & > *:nth-child(#{$n}) {
    flex: 1 0 0;
    display: flex;
    align-items: $align;
    position: $parentPosition;
  }
}

@mixin grow-last-n($n) {
  & > *:nth-last-child(#{$n}) {
    flex: 1 0 0;
    display: flex;
    align-items: center;
    position: relative;
  }
}

.container {
  --def: var(--spacing-base);

  --card-padding: var(--set-card-padding, var(--def));
  padding: var(--card-padding);

  border-radius: var(--set-card-border-radius, var(--def));
  border: var(--set-card-border, none);

  background-color: var(--set-card-background, var(--white));
  --card-row-padding: var(--set-card-row-padding, var(--def));

  --card-width: var(--set-card-width, 100%);
  width: var(--card-width);

  @media (min-width: $tablet-min-breakpoint) and (max-width: $tablet-max-breakpoint) {
    width: var(--set-card-width-tablet, var(--card-width));
    height: var(--set-card-height-tablet, var(--card-height));
    padding: var(--set-card-padding-tablet, var(--card-padding));
  }

  @media (max-width: $mobile-max-breakpoint) {
    width: var(--set-card-width-mobile, var(--card-width));
    height: var(--set-card-height-mobile, var(--card-height));
    padding: var(--set-card-padding-mobile, var(--card-padding));
  }

  --card-height: var(--set-card-height, auto);
  height: var(--card-height);

  margin-bottom: var(--set-card-margin-bottom, 0);

  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.fullHeight {
  --set-card-height: 100%;

  .content img {
    height: 100%;
    width: 100%;
    max-width: none;
    object-fit: cover;
  }
}

.cardImageAsBackground {
  position: relative;
  overflow: hidden;

  .tags,
  & > a > div:first-of-type {
    position: relative;
    z-index: 2;
  }

  .tags {
    justify-content: center;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--set-card-border-radius);
  }
}

.v-plain {
  --set-card-padding: 0;
  --set-card-border-radius: 0;
  --set-card-row-padding: 0;
}

.v-custom-projects-recent {
  --set-card-padding: 0;
  --set-card-row-padding: var(--spacing-s);
  --set-card-border-radius: 0;
  --set-card-margin-bottom: var(--spacing-xl);

  @media (min-width: $tablet-min-breakpoint) {
    --set-card-row-padding: var(--spacing-m);
  }

  &:last-child {
    margin-bottom: 0;
  }

  .content {
    @include grow-n(1);
  }
}

.v-custom-projects-tall {
  --set-card-padding: 0;
  --set-card-border-radius: 0;
  --set-card-margin-bottom: 72px;

  @media (min-width: $tablet-min-breakpoint) {
    --set-card-height: 100%;
    --set-card-margin-bottom: 0;

    .content {
      @include layout-flex;
      @include grow-n(1);

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.v-storyblok-card-grid {
  --set-card-padding: 0;
  --set-card-border-radius: 0;
  --set-card-margin-bottom: var(--spacing-m);
  --set-card-width-mobile: var(--storyblok-card-width-mobile, 85vw);
  --set-card-row-padding: var(--spacing-s);

  img {
    width: 100%;
  }

  @media (min-width: $tablet-min-breakpoint) {
    --set-card-width-tablet: var(--storyblok-card-width-tablet, 75vw);
    --set-card-row-padding: var(--spacing-m);
    --set-card-margin-bottom: var(--spacing-xl);
  }

  .content {
    @include grow-n(1);
  }

  .content > div {
    overflow: hidden;
  }
}

.v-home-about {
  --set-card-padding: 0;
  --set-card-border-radius: 0;
  --set-card-width: 315px;

  --padding: var(--spacing-s);
  @media (min-width: $desktop-min-breakpoint) {
    --padding: var(--spacing-base);
  }
  padding-top: var(--padding);
  --set-card-row-padding: var(--padding);

  height: 100%;
  border-top: 1px solid var(--black);

  .content {
    @include layout-flex;
    @include grow-n(3, 'flex-start');
    & > div {
      padding: 0;
    }
  }
}

.v-home-tools {
  --set-card-border-radius: 32px;

  --set-card-width: 424px;
  --set-card-height: 424px;
  --set-card-padding: 32px;

  --set-card-width-tablet: 380px;
  --set-card-height-tablet: 380px;
  --set-card-padding-tablet: 27px 42px;

  --set-card-width-mobile: 320px;
  --set-card-height-mobile: 320px;
  --set-card-padding-mobile: 19px 42px;

  --set-card-background: var(--white);

  .content {
    @include layout-flex-center;
    @include grow-n(2, center, static);
  }

  --set-card-row-padding-last: 0;
}

.v-home-articles {
  --set-card-padding: 0;
  --set-card-border-radius: 0;
  --set-card-row-padding: var(--spacing-s);
  --set-card-margin-bottom: var(--spacing-xl);

  @media (min-width: $tablet-min-breakpoint) {
    --set-card-row-padding: var(--spacing-m);
  }
}

// page : /articles-guides card variant
.v-articles-card {
  --set-card-padding: 0;
  --set-card-border-radius: 0;
  --set-card-margin-bottom: var(--spacing-m);
  --set-card-width-mobile: var(--storyblok-card-width-mobile, 85vw);
  --set-card-row-padding: var(--spacing-s);

  img {
    width: 100%;
  }

  @media (min-width: $tablet-min-breakpoint) {
    --set-card-width-tablet: var(--storyblok-card-width-tablet, 75vw);
    --set-card-row-padding: var(--spacing-m);
    --set-card-margin-bottom: var(--spacing-xl);
  }

  .content {
    @include grow-n(1);
  }

  .content > div {
    overflow: hidden;
  }
}

.v-home-typeface {
  --set-card-padding: 0 50px;
  --set-card-border-radius: 24px;
  --set-card-border: 1px solid var(--grey);
  // --set-card-width: 300px;
  --set-card-height: 150px;
  --set-card-height-mobile: 120px;
  --set-card-row-padding: 8px;
  --set-card-row-padding-last: 0;
  --set-card-background: transparent;
  white-space: nowrap;

  &:hover {
    background-color: var(--hover-color);
  }

  .content {
    @include layout-flex-center;
    justify-content: center;
  }
}

.v-tools-cards {
  --set-card-border-radius: 32px;
  --set-card-padding: 51px;
  --set-card-padding-tablet: 36px;
  --set-card-padding-mobile: 34px;
  --set-card-row-padding: 32px;
  --set-card-row-padding-last: 0;
  --set-card-height: 640px;
  --set-card-height-tablet: 500px;
  --set-card-height-mobile: 90vw;
  --set-card-background: var(--white);

  .content {
    @include layout-flex-center;
    @include grow-n(2, center, static);
  }
}

// Product Cards on /goods page
.v-goods-card {
  --set-card-padding: 0;
  --set-card-border-radius: 0;
  --set-card-row-padding: var(--spacing-s);
  --set-card-margin-bottom: var(--spacing-m);

  @media (min-width: $tablet-min-breakpoint) {
    --set-card-row-padding: var(--spacing-m);
  }

  @media (min-width: $desktop-min-breakpoint) {
    --set-card-margin-bottom: var(--spacing-xl);
  }
}

.v-opentype {
  --set-card-padding: 24px;
  --set-card-padding-mobile: 16px;
  --set-card-border: 1px solid var(--grey);
  --set-card-border-radius: var(--border-radius);
  --set-card-width-mobile: 100%;
  --set-card-height: 100%;
  --set-card-row-padding: var(--spacing-base);

  &:hover {
    background: var(--grey-primary);
    --toggle-on-background: var(--color-primary);
    --set-card-border: 1px solid var(--grey-mid);
  }
}

.v-in-use {
  --set-card-padding: 0;
  --set-card-margin-bottom: var(--spacing-2xl);
  --set-card-row-padding: var(--spacing-s);
  --set-card-border-radius: 0;

  .content > *:nth-child(2) {
    margin-bottom: 0;

    @media (min-width: $tablet-min-breakpoint) {
      margin-bottom: 4px;
    }
  }

  @media (min-width: $tablet-min-breakpoint) {
    --set-card-row-padding: var(--spacing-m);
  }
}

// work together with <Modal .cardModal styles
.v-in-use-modal {
  --set-card-border-radius: 0;
  --set-card-row-padding: 0;

  // remove card default padding/margin
  --row-padding: 0;
  --grid-margin: 0;
  --gutter: 0;

  overflow: hidden;

  @media (min-width: $tablet-min-breakpoint) {
    height: calc(100vh - var(--modal-margin) * 2);
    max-height: 600px;
  }

  @media (min-width: $desktop-min-breakpoint) {
    --set-card-padding: 0;
  }

  .content {
    @include layout-flex;

    & > *:nth-last-child(1) {
      flex-grow: 1;

      & > *:nth-last-child(1) {
        height: 100%;
      }
    }
  }
}

.v-related-goods {
  --set-card-padding: 0;
  --set-card-margin-bottom: 0;
  --set-card-row-padding: var(--spacing-base);
  --sat-card-border-radius: 0;
}

.link {
  text-decoration: none;
}

.content {
  flex-grow: 1;

  & > * {
    margin-bottom: var(--card-row-padding);
  }
  & > *:last-child {
    margin-bottom: 0;
  }
}

.buttonWrapper {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0;
  text-align: inherit;
}

.tags {
  display: flex;
  flex-direction: row wrap;
  margin-top: var(--card-row-padding);
}

.layout-flex {
  @include layout-flex;
}

.layout-flex-center {
  @include layout-flex-center;
}

// leave for later, not sure if we want to have static classes for each or not
// @for $i from 1 through 4 {
//   .grow-#{$i} {
//     @include grow-n($i);
//   }
// }

.cardHoverEffect {
  .content a,
  .content {
    overflow: hidden;
  }

  img {
    transition: transform 0.55s var(--transiton-timing-ease);
    transform: scale(1);
  }

  h5 a {
    text-decoration: none;
  }

  &:hover {
    h4,
    h5 {
      text-decoration: underline;
    }

    img {
      transition: transform 0.4s var(--transiton-timing-ease);
      transform: scale(1.02);
    }
  }
}

.clickEventButton {
  display: flex;
  flex-direction: column;
}

.disableBackground {
  background-color: transparent;
}

.grayscaleEffect {
  img {
    filter: grayscale(1);
  }

  &:hover {
    img {
      filter: grayscale(0);
    }
  }
}
