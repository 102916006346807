@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.CheckboxPanel {
  position: relative;

  --_checkbox-size: var(--checkbox-size, 24px);
  --_checkbox-size__small: var(--checkbox-size, 16px);

  input:focus-visible ~ .CheckboxPanelLabel {
    outline: 1px dashed var(--checkbox-border-color);
    outline-offset: 2px;
  }
}

.CheckboxPanel input:checked {
  & + .CheckboxPanelLabel {
    background-color: var(--white);
    border-color: transparent;
  }

  & ~ .CheckboxPanelLabel::before {
    background: var(--checkbox-icon) center / calc(var(--_checkbox-size) * 0.6) no-repeat;
    background-color: var(--checkbox-checked-background);
  }

  & ~ .CheckboxPanelLabel {
    .CheckboxPanel__ImageLabel {
      color: var(--black);
    }
  }
}

.CheckboxPanelLabel {
  position: relative;
  width: 100%;
  padding: var(--spacing-m);
  margin-bottom: 0;
  border-radius: 16px;
  border: 1px solid var(--checkbox-border-color);
  user-select: none;
  cursor: pointer;

  &:before {
    margin-right: 24px;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: var(--grey-mid);
      border-color: transparent;
    }
  }

  &__Content {
    display: flex;
    align-items: center;
  }

  &::before {
    position: absolute;
    top: var(--spacing-m);
    left: var(--spacing-m);

    border-radius: 50%;
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: var(--_checkbox-size);
    min-width: var(--_checkbox-size);
    height: var(--_checkbox-size);
    border: 1px solid var(--checkbox-border-color);
    background-color: var(--checkbox-background);
    margin-right: 8px;
  }
}

.CheckboxPanel__ImageWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  min-height: 112px;
}

.CheckboxPanel__ImageLabel {
  flex: 0 0 50%;
  padding-right: var(--spacing-base);
  color: var(--checkbox-checked-image-label-color);
  font-size: rem-calc(20);
  text-transform: capitalize;
}

.CheckboxPanel__Image {
  position: relative;

  flex: 0 1 50%;
  overflow: hidden;
  text-align: right;

  svg {
    width: 100%;
    height: 100%;
    max-width: 112px;
    /* Temporary fix */
    transform: scale(1.1);
  }
}

/* Icons */
.iconVariant-onWhite {
  .stroke {
    fill: #231f20;
  }
  .fill {
    fill: #d3d3d3;
  }

  &:hover {
    .fill {
      fill: #c0c0c0;
    }
  }
}

.iconVariant-onDark {
  .stroke {
    fill: white;
  }
  .fill {
    fill: #ffffff26;
  }

  &:hover {
    .stroke {
      fill: var(--charcoal);
    }
    .fill {
      fill: transparent;
    }

    .CheckboxPanel__ImageLabel {
      color: var(--charcoal);
    }

    .CheckboxPanelLabel {
      color: var(--black);

      &::before {
        border-color: var(--charcoal);
      }
    }
  }

  input:checked ~ .CheckboxPanelLabel {
    .stroke {
      fill: var(--black);
    }
    .fill {
      fill: transparent;
    }
  }
}
