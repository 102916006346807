@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.OrdersTableContainer {
  width: 100%;
  margin-bottom: var(--spacing-l);

  @media screen and (min-width: $desktop-min-breakpoint) {
    width: 80%;
  }
}

.OrdersTable {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  .OrdersTableItem:last-child {
    display: none;
  }

  @media (max-width: $mobile-max-breakpoint) {
    td {
      width: 50%;

      &:nth-child(2),
      &:nth-child(3) {
        display: none;
      }

      &:last-child {
        display: none;
      }

      h6 {
        padding-bottom: 12px;
      }

      p {
        padding-bottom: 12px;
      }

      button {
        margin-top: -6px;
      }
    }
  }

  @media screen and (min-width: $tablet-min-breakpoint) {
    td {
      width: 25%;
      text-align: center;

      &:first-of-type {
        display: none;
      }

      &:nth-child(2) {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }

    .OrdersTableItem:last-child {
      display: block;
    }
  }
}

.OrdersTableItem {
  display: flex;
  align-items: center;
  padding: 8px;
  flex: 1;
  justify-content: space-between;

  &:last-child {
    flex: none;
  }

  @media screen and (min-width: $tablet-min-breakpoint) {
    padding: 0;
  }

  &__ButtonMobile {
    display: block;

    @media screen and (min-width: $tablet-min-breakpoint) {
      display: none;
    }
  }
}

.InvoiceRowTitle {
  display: none;
}

.InvoiceRowDate {
  display: none;
}

.digits {
  font-family: 'Basis-Grotesque-Mono-Pro';
}
